import React, {useEffect} from "react";
import useNativeAuthentication from "./hooks/useNativeAuthentication";
import {useSelector} from "react-redux";
import {selectPendingActivationToken, selectShowActivation, selectUser} from "../store/selectors";
import NativeMainPage from "./Pages/NativeMainPage";
import "./NativeApp.css"
import {OnboardingRouter} from "./Onboarding/OnboardingRouter";
import {PaywallView} from "./Pages/Subscriptions/PaywallViewPlugin";
import {usePaywallInitializer} from "./Pages/Subscriptions/usePaywallInitializer";
import {ActivationPage, ActivatingPage} from "./Pages/Activation";

const AuthenticationRouter = () => {
  const user = useSelector(selectUser);
  const {isAuthenticated} = useNativeAuthentication();
  const paywallInitialized = usePaywallInitializer();
  const [paywallLoggedIn, setPaywallLoggedIn] = React.useState(false);
  const showActivation = useSelector(selectShowActivation);
  const pendingActivationToken = useSelector(selectPendingActivationToken);

  useEffect(() => {
    if(user?.userId && paywallInitialized && !paywallLoggedIn) {
      void PaywallView.login({
        userId: user.userId,
      })
      setPaywallLoggedIn(true)
    }
  }, [paywallInitialized, user, paywallLoggedIn]);

  if (pendingActivationToken && isAuthenticated === true) {
    return (
      <ActivatingPage />
    );
  }

  if (showActivation === true && isAuthenticated === true) {
    return (
      <ActivationPage />
    )
  }

  return (
    isAuthenticated
      ? <NativeMainPage/>
      : <OnboardingRouter/>
  )
}

export default AuthenticationRouter;
