import { createStore } from "redux";
import rootReducer from "./reducers/index";

const store = createStore(
    rootReducer,
    // @ts-ignore
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;

export type RootState = ReturnType<typeof rootReducer>;
