import React from "react";
import styles from "./HelloUserCard.module.css";
import Moment from "react-moment";
import {HelloUserCardResponse} from "./HelloUserCardResponse";

type Props = {
  card : HelloUserCardResponse,
}

export function HelloUserCard({card} : Props) {
    return (
        <div className={styles.container}>
          <div className={styles.today}><Moment format="ddd, MMM DD">{new Date()}</Moment></div>
          <div className={styles.welcomeMessage}>Hello, {card.userName} 👋</div>
        </div>
    )
}
