export enum PreferencesActionState {
  enable = "enable",
  disable = "disable"
}

export enum UserPreferencesSettingState {
  turn_on = "turn_on",
  turn_off = "turn_off"
}

export enum PreferencesCardStatus {
  normal = "normal",
  loading = "loading",
  updating = "updating",
  error = "error"
}
