import React from "react";
import styles from "./ChatPage.module.css";
import ChatView from "../../../components/Chat/ChatView";

const ChatPage : React.FC = () =>{
  return (
    <div className={styles.container}>
      <ChatView />
    </div>
  )
}

export default ChatPage
