import {AppPage} from "./models";
import { RouteProps } from "react-router-dom";
import {LoginPagerRoutes} from "../Onboarding/LoginPageRoutes";
import {PracticePagerRoutes} from "../Pages/Practice/PracticePageRoutes";
import {ProfilePagerRoutes} from "../Pages/Profile/ProfilePageRoutes";
import {JournalsPageRoutes} from "../Pages/Journals/JournalsPageRoutes";
import {TodayPagerRoutes} from "../Pages/Today/TodayPageRoutes";
import {FunctionComponent} from "react";
import {LogoutPagerRoutes} from "../Logout/LogoutPageRoutes";
import {ChatPageRoutes} from "../Pages/Chat/ChatPageRoutes";
import {PaywallPageRoutes} from "../Pages/Subscriptions/PaywallPageRoutes";
import {ActivationRoutes} from "../Pages/Activation/ActivationRoutes";

export interface AppPageRoutes{
  page : AppPage
  routes : RouteProps[]
  deepLinkPath : string;
  component? : FunctionComponent<any>
}

export const AppRoutes : AppPageRoutes[] = [
  new LoginPagerRoutes(),
  new LogoutPagerRoutes(),
  new PracticePagerRoutes(),
  new ProfilePagerRoutes(),
  new JournalsPageRoutes(),
  new TodayPagerRoutes(),
  new ChatPageRoutes(),
  new PaywallPageRoutes(),
  new ActivationRoutes(),
]

