
export enum AppEventType {
    applicationReloaded = "applicationReloaded",
    pushTokenRegistered = "pushTokenRegistered",
    pushTokenCreationError = "pushTokenCreationError",
}

export interface IAppEvent {
    type : AppEventType,
}
