import React, {ReactNode} from "react";
import styles from "./index.module.css"
import Card from "../Card";
import {CardResponse} from "../../../../model/CardsResponse";
import useTranslation from "../../../../locale/hooks/use-translation";

type Props = {
  card : CardResponse,
  children : ReactNode;
}

const WidgetCard : React.FC<Props> =({card, children} : Props) => {
  const {t} = useTranslation();

  return (
      <Card backgroundColor={"#ffffff"} title={card.titleKey ? t(card.titleKey) : card.title}>
        <div className={styles.container}>
          {children}
        </div>
      </Card>
  )
}

export default WidgetCard
