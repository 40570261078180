import {callApi, CallMethod} from "./api";
import store from "../store";
import {setShowActivation, setLogoutUser, setShowPaywall, setUserState} from "../store/actions";
import {UserStateInfo} from "../store/reducers";
import {deleteUserInfo, persistUserInfo} from "../NativeApp/services/user_repository";
import {UserAuthResponse} from "./models";
import {trackEvent} from "./analytics_tracker";
import {OAuthSignInDetails} from "../NativeApp/Onboarding/OAuthSignIn/OAuthPlugin/models";

export async function authenticateUser(token : string){
  try {
    const authResponse = await callApi<UserAuthResponse>(CallMethod.get, `user/auth`, {data : {token : token}});
    return await handleSuccessfulAuth(authResponse);
  } catch (error) {
    return null;
  }
}

export async function authenticateOAuthUser(details : OAuthSignInDetails){
  try {
    const authResponse = await callApi<UserAuthResponse>(CallMethod.post, `user/auth/${details.provider}`, {data : details});
    return await handleSuccessfulAuth(authResponse);
  } catch (error) {
    return null;
  }
}

async function handleSuccessfulAuth(authResponse : UserAuthResponse){
  const userState : UserStateInfo = {
    token: authResponse.token,
    sourceId: authResponse.user.sourceId,
    userId: authResponse.user.userId,
    chat: authResponse.chat
  }

  await persistUserInfo(userState);

  store.dispatch(setUserState(userState))
  if(authResponse.activation === true){
    store.dispatch(setShowActivation(true));
  } else if (authResponse.created === true){
    store.dispatch(setShowPaywall(true));
  }

  trackEvent("User Logged In To App");

  return authResponse;
}

export async function logoutUser(){
  try{
    await deleteUserInfo();
    store.dispatch(setLogoutUser());
  }catch (error){
    console.log("Cannot logout user");
  }
}
