export interface HappinessScoreCardTheme {
  name: string,
  scoreTextColor: string,
  maxScoreTextColor: string,
  separatorTextColor: string,
  badge: HappinessScoreCardBadgeTypes
}

export enum HappinessScoreCardBadgeTypes{
    orangeHeart = "orange_heart",
    pinkHeart = "pink_heart",
    blueHeart = "blue_heart",
    greenTrophy = "green_trophy"
}

