import React from "react";

const BinIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={18}
    viewBox="0 0 16 18"
    {...props}
  >
    <defs>
      <filter id="prefix__a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.243137 0 0 0 0 0.200000 0 0 0 0 0.188235 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g filter="url(#prefix__a)" fill="none" fillRule="evenodd">
      <path
        fill="#000"
        fillRule="nonzero"
        d="M15.2 3.7a.5.5 0 00-.5-.5h-4V1.512C10.7.677 10.023 0 9.188 0H6.012C5.177 0 4.5.677 4.5 1.512V3.2h-4a.5.5 0 000 1h1.2v11.05A2.754 2.754 0 004.45 18h6.3a2.754 2.754 0 002.75-2.75V4.2h1.2a.5.5 0 00.5-.5zM5.5 1.512c0-.283.23-.512.512-.512h3.176c.283 0 .512.23.512.512V3.2H5.5V1.512zm7 13.738A1.752 1.752 0 0110.75 17h-6.3a1.752 1.752 0 01-1.75-1.75V4.2h9.8v11.05z"
      />
    </g>
  </svg>
);

export default BinIcon;
