import React from "react";
import styles from "./index.module.css"
import Card from "../containers/Card";
import {InviteFriendsCardResponse} from "./InviteFriendsCardResponse";

import inviteFriendsImage from "./assets/invite-friends.png";
import {Share} from "@capacitor/share";

type Props = {
  card : InviteFriendsCardResponse,
}

const InviteFriendsCard : React.FC<Props> =({card})=> {
  const shareInvite = () => {
    Share.share(card.shareOptions)
      .then((result) => {
        console.log("Share completed");
        console.log(result);
      })
      .catch(() => {
        console.log("Share canceled");
      })
  }

  return (
      <Card backgroundColor={"#ff88c1"} title={card.title}>
        <div className={styles.container} onClick={()=>{shareInvite()}}>
            <img src={inviteFriendsImage} className={styles.inviteImg} alt={""}/>
            <div className={styles.title}>We're all on this <br/> journey together...</div>
          <div className={styles.button}><div className={styles.text}>Invite a friend</div></div>
        </div>
      </Card>
  )
}

export default InviteFriendsCard
