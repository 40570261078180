import {AppPage} from "../../routing/models";
import {AppPageRoutes} from "../../routing/AppRoutes";
import TodayTabPage from "./TodayTabPage";

export class TodayPagerRoutes implements AppPageRoutes {
  page = AppPage.today;
  component = TodayTabPage;
  routes = [{
    path: "/today",
    exact : true
   }
  ]
  deepLinkPath = "/today"
}
