import React from "react";
import Switch from "react-switch";

type SwitchProps = {
  onChange: any;
  checked: boolean;
  disabled: boolean;
};

const SwitchComponent = ({ onChange, checked, disabled }: SwitchProps) => {
  return (
    <Switch
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      offColor="#d9d9d9"
      onColor="#b2e2b8"
      handleDiameter={17}
      width={40}
      height={21}
      checkedIcon={false}
      uncheckedIcon={false}
      boxShadow="0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05)"
    />
  );
};

export default SwitchComponent;
