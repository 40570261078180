import CardsBasedTabPage from "../CardsBasedTabPage";
import React from "react";
import AnalyticsTracker from "../../../components/AnalyticsTracker";
import {IonContent} from "@ionic/react";

const TodayTabPage = () =>{

  return (
    <AnalyticsTracker event={"App Today Tab Loaded"}/> &&
    <IonContent>
      <CardsBasedTabPage tabName={"today"} />
    </IonContent>
  )
}

export default TodayTabPage;
