import {Preferences} from '@capacitor/preferences';
import {UserStateInfo} from "../../store/reducers";

const storageKey = "user"

const persistUserInfo = async (user : UserStateInfo) => {
  const userInfo = JSON.stringify(user);

  console.log("Persisting user", user);

  await Preferences.set({
    key: storageKey,
    value: userInfo,
  });
};

const deleteUserInfo = async ()=>{
  await Preferences.remove({
    key: storageKey,
  });
}

const getUserPersistedInfo = async () : Promise<UserStateInfo | null> => {
  const { value } = await Preferences.get({ key: storageKey });

  const stringValue = value || process.env.REACT_APP_USER_DETAILS;

  console.log("User Details", value, stringValue);

  if(!stringValue || stringValue === "") return null;

  return JSON.parse(stringValue);
};

export {
  persistUserInfo,
  getUserPersistedInfo,
  deleteUserInfo
}
