import {PushNotifications} from '@capacitor/push-notifications';
import {Preferences} from "@capacitor/preferences";

export const getPushNotificationToken = async () : Promise<string> => {
  const { value } = await Preferences.get({key: "push_token"});
  console.log("Push Token", value)
  return value;
}

export const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    console.log('no permission for push received')
    return;
  }

  await PushNotifications.register();
}
