import React, {MouseEvent} from 'react';
import styles from "../../index.module.css";
import useTranslation from "../../../../../locale/hooks/use-translation";

type UpdateErrorProps = {
  handleRetry: (e: MouseEvent) => void,
}

const UpdateError: React.FC<UpdateErrorProps> = ({handleRetry}) => {
  const {t} = useTranslation();

  return (
    <div className={styles.errorWrapper}>
      <div className={styles.error}>{t("card.preferences.update-error")}</div>
      <div role="img" aria-label="upside-down-face" className={styles.emoji}>🙃</div>
      <div className={styles.retry} onClick={handleRetry}>{t("card.preferences.update-retry")}</div>
    </div>
  )
}

export default UpdateError;
