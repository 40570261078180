import React, {useEffect, useState} from "react";
import ReactPlayer from "react-player";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {callApi, CallMethod} from "../../common/api";
import {Exercise} from "../../NativeApp/Pages/Practice/model";
import {RouteComponentProps} from "react-router-dom";
import {AppHeader} from "../AppHeader/AppHeader";
import styles from "./index.module.css"
import AnalyticsTracker from "../AnalyticsTracker";

interface PracticePageProps
  extends RouteComponentProps<{
    exerciseName: string;
  }> {}

const ExercisePlayer : React.FC<PracticePageProps> = ({ match }) => {
  const windowDimensions = useWindowDimensions();
  const [exercise, setExercise] = useState<Exercise>(null);

  useEffect(() => {
    callApi<Exercise>(CallMethod.get, "/exercises/" + match.params?.exerciseName)
      .then((exercise) => {
        setExercise(exercise)
      })
  }, [])

  return (
    <div className={styles.practice}>
      <AnalyticsTracker event="Mobile Web Page Visit" params={{ page : "practice" }}/>
      <AppHeader value={null}/>
      {exercise
        ? <div className={styles.container}>
          <div className={styles.title}>{exercise.title}</div>
          <div className={styles.playerContainer}>
            <ReactPlayer url={exercise.mediaUrl}
                         controls={true}
                         playing={true}
                         width={windowDimensions.width - 40}
            /></div>
        </div>
        : null
      }
    </div>
  );
}

export default ExercisePlayer;
