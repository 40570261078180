import React from 'react';
import {ResponsiveLine} from '@nivo/line';
import EmojiPath from './EmojiPath';
import styles from './index.module.css';
import moment from 'moment';
import {DateFormat, useLocale} from "../../../../locale";
import useTranslation from "../../../../locale/hooks/use-translation";

const allDates = (data : any)=>{
  console.log("All objects data", data);

  const dates = data.map((entry : any) => {
    return entry.x;
  })

  return [
    moment(data[0].x).subtract(10, "day"),
    ...dates
  ]
}

interface ChartProps {
  data: any[],
  isSundayAsFirstWeekday?: boolean
}

const defaultProps: ChartProps = {
  data: [],
  isSundayAsFirstWeekday: true
}

const getTickValue = (totalDays : number) =>{
  if(totalDays <= 50) return "every 1 week";
  if(totalDays <= 120) return "every 2 weeks";
  return "every 2 months";
}

const getFirstDayPaddingValue = (totalDays : number) =>{
  if(totalDays <= 60) return 2;
  if(totalDays <= 120) return 3;
  return 5;
}

const HappinessChart: React.FC<ChartProps> = (props) => {
  const {data, isSundayAsFirstWeekday} = props;
  const {formatDate} = useLocale();
  const {t} = useTranslation();

  const lastDate = data[data.length - 1].x;
  const totalDays = moment(lastDate).diff(data[0].x, 'days');
  const firstDate = moment(data[0].x).subtract(getFirstDayPaddingValue(totalDays), "day").toISOString()

  moment.updateLocale('en', {
    week: {
      dow: isSundayAsFirstWeekday ? 0 : 1
    }
  })

  return (
    <ResponsiveLine
      data={[{
        id: 'happiness_score',
        data
      }]}
      margin={{ top: 10, right: 15, bottom: 0, left: 15 }}
      xScale={{type: 'time', format: '%Y-%m-%dT%H:%M:%S.%LZ', useUTC: false, precision: 'day', min: firstDate, max: lastDate}}
      xFormat="time:%Y-%m-%dT%H:%M:%S.%LZ"
      yScale={{ type: 'linear', stacked: false, min: 0, max: 5 }}
      curve="linear"
      axisLeft={{
        tickValues: [
          1,
          2,
          3,
          4,
          5,
        ],
        renderTick: (values: any) => (
            <g
              transform={`translate(${values.x - 15},${values.y - 10})`}
              fill="none"
              fillRule="evenodd"
            >
              <path
                fill="#EFEAE1"
                d="M19.986 9.993c0 5.52-4.474 9.993-9.993 9.993S0 15.512 0 9.993 4.474 0 9.993 0c5.52 0 9.993 4.474 9.993 9.993"
                transform="translate(-31 -436) translate(10 337) translate(21 99) translate(0 .103)"
              />
              <EmojiPath status={values.value}/>
            </g>
        ),
        tickSize: 0,
        tickPadding: 0,
        tickRotation: 0,
      }}
      axisRight={{
        tickValues: [
          1,
          2,
          3,
          4,
          5,
        ],
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
      }}
      axisBottom={{
        tickValues: getTickValue(totalDays),
        format: value => {
          return `\u00A0 ${formatDate(value as string, { format: DateFormat.INVERTED_RANGE })}`;
        },
        tickSize: 0,
        tickPadding: -4,
        tickRotation: 0.1,
      }}
      enableGridX={false}
      enableGridY={true}
      colors={['#9fd4ba']}
      lineWidth={1}
      pointSize={4}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={4}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      enableSlices='x'
      gridXValues={allDates(data)}
      theme={{
        fontSize: 9,
        textColor: '#3e3330',
        grid: {
          line: {
            stroke: '#fbeedd',
          }
        }
      }}
      sliceTooltip={({slice}) => {
        return (
          <div className={styles.tooltip}>
            <div
              className={styles.score}
              style={{color: slice.points[0].serieColor}}
            >
              {t('card.happiness-tracker.chart-tooltip')} <strong>{slice.points[0].data.yFormatted}</strong>
            </div>
            <div className={styles.time}>{formatDate(slice.points[0].data.xFormatted as string, { format: DateFormat.FULL_DATE })}</div>
          </div>
        )
      }}

    />
  )
}

HappinessChart.defaultProps = defaultProps;
export default HappinessChart;
