import {AppPage} from "../../routing/models";
import {AppPageRoutes} from "../../routing/AppRoutes";
import PaywallPage from "./PaywallPage";

export class PaywallPageRoutes implements AppPageRoutes{
    page = AppPage.paywall;
    component = PaywallPage;
    routes = [{
        path : "/paywall",
        exact : true
      }
    ]
  deepLinkPath = "/paywall"
}
