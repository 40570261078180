import React, {useEffect} from "react";
import {IonContent} from '@ionic/react';
import {callApi, CallMethod} from "../../../common/api";
import {useDispatch, useSelector} from "react-redux";
import {GlobalAppState} from "../../../store/reducers";
import {setTabData} from "../../../store/actions";
import {Exercise, PracticeTabResponse} from "./model";
import ExerciseItem from "./ExerciseItem";
import styles from "./PracticePageTab.module.css"
import AnalyticsTracker from "../../../components/AnalyticsTracker";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {playExerciseMedia} from "./exercise_media_player";
import {PracticePagerRoutes} from "./PracticePageRoutes";

const tabName = "practice";

interface PracticePageProps
  extends RouteComponentProps<{
    exerciseName: string;
  }> {}

const PracticePage : React.FC<PracticePageProps> = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const pageRoute = new PracticePagerRoutes()

  const exercises : Exercise[] = useSelector((state: GlobalAppState) => {
    return state.tabsData[tabName];
  })

  useEffect(() => {
    const autoStartExerciseIfNeeded = () => {
      if(!match.params?.exerciseName) return;

      const exerciseToStart = exercises.find(exercise => exercise?.title.toLowerCase().trim() === match.params.exerciseName.toLowerCase().trim());
      if (exerciseToStart) {
        playExerciseMedia(exerciseToStart);
      }
      history.replace(pageRoute.deepLinkPath);
    }

    if(!exercises){
      callApi<PracticeTabResponse>(CallMethod.get, `tabs/practice`).then(response => {
        dispatch(setTabData(tabName, response.payload.exercises))
      })
      return;
    }

    autoStartExerciseIfNeeded();
  }, [exercises])

  const renderExercises = (exercises : Exercise[]) => {
    return (
      <div className={styles.container}>
        <div className={styles.pageTitle}>Practice</div>
        {exercises.map((exercise, index) => {
          return <ExerciseItem item={exercise} key={index}/>
        })}
      </div>
    )
  }

  return (
    <IonContent>
      <AnalyticsTracker event={"App Practice Tab Loaded"}/>
      <div className={styles.tabPage}>
        {exercises?.length
          ? renderExercises(exercises)
          : <div className="loadingContainer">Loading...</div>
        }
      </div>
    </IonContent>
  )
}

export default PracticePage
