import React, {useState} from 'react';
import {selectUserPreferencesSettings} from '../../../../../../store/selectors';
import {useDispatch, useSelector} from 'react-redux';
import styles from './index.module.scss';
import {ToggleSettingType} from '../../../../../../store/reducers';
import SwitchComponent from '../../../../../Switch';
import {SET_USER_PREFERENCES} from '../../../../../../store/actionTypes';
import {trackUserPreferenceSettingUpdate} from '../../../services/preferences-events-tracker';
import {invokeUserPreferencesAction, UpdateStatusType} from "../../../services/user_preferences_api_invoker";
import {PreferencesActionState, UserPreferencesSettingState} from "../../../models";
import useTranslation from "../../../../../../locale/hooks/use-translation";

export const SettingsItem = ({text, name, state}: ToggleSettingType) => {
  const userPreferencesSettings = useSelector(selectUserPreferencesSettings);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleChange = async (checked: boolean) => {
    setDisabled(true);
    const action = checked ? PreferencesActionState.enable : PreferencesActionState.disable;
    try{
      const response = await invokeUserPreferencesAction(name, action)

      if(response.status === UpdateStatusType.success) {
        trackUserPreferenceSettingUpdate(name, checked ? UserPreferencesSettingState.turn_on : UserPreferencesSettingState.turn_off);

        setDisabled(false);

        dispatch({
          type: SET_USER_PREFERENCES,
          payload: {
              settings: userPreferencesSettings?.map(setting => {
                return setting.name !== name ? setting : ({...setting, state: checked})
              })
          }
        });
      }
      else {
        throw new Error('Updated Failed');
      }
    }
    catch(e) {
      console.log('update error:', e);
    }
  }

  return (
    <div className={styles.list}>
      <div className={styles.title}>{t(`card.preferences.settings.${name}.text`, { defaultText: text })}</div>
      <SwitchComponent checked={state} onChange={handleChange} disabled={disabled}/>
    </div>
  )
}
