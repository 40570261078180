import React from "react";
import { WelcomeCard } from "./WelcomeCard/WelcomeCard";
import { JournalTrackerCard } from "./JournalTrackerCard/JournalTrackerCard";
import { HappinessTracker } from "./HappinessTracker/HappinessTracker";
import { UserJourneyCard } from "./UserJourneyCard/UserJourneyCard";
import UserPreferencesCard from "./UserPreferencesCard";
import {TopicsWordCloudCard} from "./TopicsWordCloudCard/TopicsWordCloudCard";
import JournalsCard from "./JournalsCard/JournalsCard";
import {CardResponse, CardsType} from "../../model/CardsResponse";
import InviteFriendsCard from "./InviteFriendsCard";
import styles from "./CardsList.module.css"
import ContentCard from "./containers/ContentCard";
import {JournalingStatsCard} from "./JournalingStatsCard/JournalingStatsCard";
import {HelloUserCard} from "./HelloUserCard/HelloUserCard";
import {HappinessScoreCard} from "./HappinessScoreCard/HappinessScoreCard";
import {ReturnToConversationCard} from "./ReturnToConversationCard/ReturnToConversationCard";
import AccountCard from "./account-card";

type Props = {
  cards : CardResponse[]
}

const CardsList : React.FC<Props> = ({cards}) => {
  return (
    <div className={styles.cardsContainer}>
      {cards.map((card: any, i: number) => {
        if (card.type === "welcome_card") {
          return <WelcomeCard card={card} key={i} />;
        }
        if (card.type === "return_to_conversation_card") {
          return <ReturnToConversationCard card={card} key={i} />;
        }
        if (card.type === "user_preferences_card") {
          return <UserPreferencesCard card={card} key={i} />;
        }
        if (card.type === "journal_tracker_card") {
          return <JournalTrackerCard card={card} key={i} />;
        }
        if (card.type === "happiness_card") {
          return <HappinessTracker card={card} key={i} />;
        }
        if(card.type === CardsType.happinessScoreCard){
          return <HappinessScoreCard card={card} key={i}/>
        }
        if (card.type === "topics_word_cloud_card") {
          return <TopicsWordCloudCard card={card} key={i} />;
        }
        if (card.type === "user_journey_card") {
          return <UserJourneyCard card={card} key={i} />;
        }
        if(card.type === "journals_card"){
          return <JournalsCard card={card} key={i} />;
        }
        if(card.type === CardsType.contentCard){
          return <ContentCard card={card} key={i}/>
        }
        if(card.type === CardsType.inviteFriendsCard){
          return <InviteFriendsCard card={card} key={i}/>
        }
        if(card.type === CardsType.journalingStatsCard){
          return <JournalingStatsCard card={card} key={i}/>
        }
        if(card.type === CardsType.helloUserCard){
          return <HelloUserCard card={card} key={i}/>
        }

        if(card.type === CardsType.accountCard){
          return <AccountCard card={card} key={i}/>
        }
        return null;
      })}
    </div>
  );
}

export default React.memo(CardsList)
