import React from "react";
import styles from "./ReturnToConversationCard.module.css";
import {ReturnToConversationCardResponse} from "./ReturnToConversationCardResponse";
import WidgetCard from "../containers/WidgetCard";
import {trackEvent} from "../../../common/analytics_tracker";
import {PlatformIconImage} from "../../PlatformIcons";

type Props = {
  card : ReturnToConversationCardResponse
}

export function ReturnToConversationCard({ card } : Props) {
  const buttonsClasses = `${styles.deepLinkButton} ${styles[card.platform]}`

  return (
    <WidgetCard card={card}>
      <div>
        <div className={styles.text}> { card.text } </div>
        <div className={styles.buttonContainer}>
          <a href={card.deepLink} target={"_blank"} onClick={() =>{
            trackEvent("Return To Conversation Button Clicked");
          }}>
            <div className={buttonsClasses}>
              <div className={styles.buttonText}> Back to </div>
              <div className={styles.platformIcon}><PlatformIconImage platform={card.platform}  imgClassName="image"/> </div>

            </div>
          </a>
        </div>
      </div>
    </WidgetCard>
  )
}
