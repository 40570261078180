import React from 'react';
import {
  MessageSimple,
  MessageText,
  MessageUIComponentProps,
  useChannelStateContext,
  useMessageContext
} from 'stream-chat-react';
import {MessageResponse} from "./models";
import MessageButtonsView from "./MessageButtonsView";

const ChatMessage: React.FC<MessageUIComponentProps> = (props) => {
  const {message} = useMessageContext();
  const {channel} = useChannelStateContext();

  return (
    <div>
      <MessageSimple {...props}>
        <MessageText {...props} />
      </MessageSimple>
      <MessageButtonsView message={message as MessageResponse} channel={channel} />
    </div>

  );
}
export default ChatMessage;
