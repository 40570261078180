import React from "react";
import {Helmet} from "react-helmet";

export default function HeadMetaTags(){
    return (
        <Helmet>
                <meta name="viewport"content="minimum-scale=1, initial-scale=1, width=device-width" />
                <meta content="Kai - Your Personal Life Coach. Powered by AI" property="og:title" />
                <meta name="description"
                content="&quot;How we spend our days is, of course, how we spend our lives.&quot; We created Kai to enable millions of people access to the benefits of personal coaching. We imagine a world in which every person has the opportunity to learn, grow, and become their own best version of themselves."
                />
                <meta content="https://uploads-ssl.webflow.com/5e5631f324830ba5880a61ad/5e727e13c16f9e714d6f245f_Kai_og_image.jpg" property="og:image"/>
                <meta content="Kai - Your Personal Life Coach. Powered by AI" property="twitter:title" />
                <meta property="twitter:description"
                content="&quot;How we spend our days is, of course, how we spend our lives.&quot; We created Kai to enable millions of people access to the benefits of personal coaching. We imagine a world in which every person has the opportunity to learn, grow, and become their own best version of themselves."
                />
                <meta content="https://uploads-ssl.webflow.com/5e5631f324830ba5880a61ad/5e727e13c16f9e714d6f245f_Kai_og_image.jpg" property="twitter:image" />
        </Helmet>
    )
}
