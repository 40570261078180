import CardsBasedTabPage from "../CardsBasedTabPage";
import React from "react";
import AnalyticsTracker from "../../../components/AnalyticsTracker";
import {IonContent} from "@ionic/react";

const JournalsTabPage = () =>{
  return (
    <AnalyticsTracker event={"App Journals Tab Loaded"}/> &&
    <IonContent>
      <CardsBasedTabPage tabName={"journals"} />
    </IonContent>
  )
}

export default React.memo(JournalsTabPage)
