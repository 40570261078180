import { JournalTopic } from "../../../common/models";

// ensure that open_journal topics always come last
export const sortTopics = (topics: readonly JournalTopic[]) => {
  return topics.slice().sort((a, b) => {
    if (a.topic === "open_journal" && b.topic !== "open_journal") {
      return 1;
    }
    if (b.topic === "open_journal" && a.topic !== "open_journal") {
      return -1;
    }
    return 0;
  });
};
