import {JournalResponse} from "../model/JournalResponse";
import {CardResponse} from "../model/CardsResponse";
import {FilterGroupType} from "../components/journal/JournalFilter/JournalFiltersList";

export type Topic = {
  magnitude: number;
  phrase: string;
};

export enum JournalEntryIconType {
  gratefulness= 1,
  focus,
  goodThings,
  affirmation,
  growth,
  weekend,
  openJournal
}

export type JournalEntry = {
  id: string;
  content: string;
  title?: string;
  topic? : string
  icon : JournalEntryIconType;
};

export type JournalTopic = {
  topic: string;
  journal_entries: JournalEntry[];
  filterGroups? : FilterGroupType[],
};

export type JournalItem = {
  date: Date;
  journals_topics: JournalTopic[];
};

export type Journal = {
  items: JournalItem[];
  minDate: number;
  maxDate: number;
};

export type UserTimezone = {
  utcOffset: number;
  utcName?: string;
  name?: string;
};

export interface IChatResponse{
  channel: string,
  appKey: string,
  token: string,
}

export type UserAuthResponse = {
  user : User,
  token : string
  chat: IChatResponse
  created: boolean
  activation: boolean
}

export type User = {
  userId : string;
  first_name: string;
  last_name: string;
  full_name: string;
  sourceId : string;
  timezone: UserTimezone;
};

export type Context = {
  userId: string;
};

// API responses

export type BasePayload<T> = {
  status: number;
  context: Context;
  response: T;
};

export type HomepagePayload = {
  user: User;
  cards: readonly CardResponse[];
  journal?: JournalResponse;
  error?: any;
  renewLink?: string;
};

export type TopicWordCloudPayload = BasePayload<{
  topics: readonly Topic[];
}>;

// Homepage models

export type Edits = {
  [id: string]: string;
};

export enum PlatformType {
  Discord = "discord",
  Telegram = "telegram",
  WhatsApp = "whatsapp",
  AppleBusinessChat = "apple_business_chat",
}
