import {useEffect} from "react";
import api from "../../common/api";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {GlobalAppState} from "../../store/reducers";
import {setCardData} from "../../store/actions";

type CardDataProviderOptions = {
  populatePath : string
}

export enum CardDataProviderResultStatus{
  loading,
  success,
  failure
}

type CardDataProviderResult<T> = {
  status : CardDataProviderResultStatus
  data : T;
}

const useCardDataProvider = <T>(cardName : string, options : CardDataProviderOptions) : CardDataProviderResult<T> => {
  const dispatch = useDispatch();

  // @ts-ignore
  const cardData = useSelector((state: GlobalAppState) =>{
      return state.cardsData[cardName];
  }, shallowEqual) as T

  useEffect(() => {
    if(cardData) return;

    api.getCardData<T>(options.populatePath).then((response)=>{
      dispatch(setCardData(cardName, response))
    }).catch(()=>{
  })
  }, []);


  return {
    data: cardData,
    status : cardData ? CardDataProviderResultStatus.success : CardDataProviderResultStatus.loading
  };
};

export default useCardDataProvider;
