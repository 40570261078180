import React, { useState } from 'react';
import styles from './index.module.css';
import TimeSetter from './TimeSetter';
import SwitchComponent from '../../../../../Switch';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserPreferences } from '../../../../../../store/selectors';
import { SET_USER_PREFERENCES } from '../../../../../../store/actionTypes';
import moment from 'moment';
import { trackRoutineStateUpdate } from '../../../services/preferences-events-tracker';
import {
  invokeUserPreferencesAction,
  UpdateStatusType,
  UserPreferencesActionType
} from "../../../services/user_preferences_api_invoker";
import {RoutineType, RoutineTypeFields} from "../models";
import {RoutineActivityState} from "../../../UserPreferencesCardResponse";
import {RoutineState, UserPreferencesType} from "../../../../../../store/reducers";
import useTranslation from "../../../../../../locale/hooks/use-translation";

type RoutineSettingsProps = {
  type: RoutineType,
  toggle: boolean;
  routine : RoutineState
}

const startOfWeekDay = moment().weekday(0).format("dddd").toLowerCase() === "sunday" ? "sunday" : "monday";

const RoutineSettings = ({routine, type, toggle} : RoutineSettingsProps) => {
  return (
    <div className={styles.root}>
    {
      routine
        ? <EnabledRoutineView type={type} routine={routine} toggle={toggle} />
        : <DisabledRoutineView type={type} />
    }
    </div>
  )
}

interface DisabledRoutineViewProps {
  type: RoutineType;
}

const DisabledRoutineView = ({type}: DisabledRoutineViewProps) => {
  const {t} = useTranslation();

  return (
    <>
      <div className={`${styles.titleWrapper} ${styles.disabled}`}>
        {t(`card.preferences.routines.${type}.title`)}
      </div>

      <i className={styles.offNote}>{t('card.preferences.routines.off')}</i>
    </>
  )
}

const EnabledRoutineView = ({type, routine}: RoutineSettingsProps) => {
  const {t} = useTranslation();
  const userPreferences = useSelector(selectUserPreferences);

  const handleRoutineActivity = async (checked: boolean) => {
    const action = checked ? UserPreferencesActionType.enable : UserPreferencesActionType.disable;
    setIsUpdating(true);

    try{
      const requestBody = {startOfWeekDay: !routine ? startOfWeekDay : ""};
      const response = await invokeUserPreferencesAction(`routine/${type}`, action, requestBody)

      if(response.status === UpdateStatusType.success) {
        const routineStateField = RoutineTypeFields[type];

        let payload : UserPreferencesType = {
          ...userPreferences,
          [routineStateField]: {
            ...userPreferences[routineStateField],
            state: checked ? RoutineActivityState.enabled : RoutineActivityState.disabled
          }
        }

        dispatch({
          type: SET_USER_PREFERENCES,
          payload : payload
        });

        trackRoutineStateUpdate(type, action)
        setIsUpdating(false);
      }
      else {
        throw new Error('Updated Failed');
      }
    }
    catch(e) {
      console.log('update error:', e);
    }
  }
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();

  const enabled = routine?.state === RoutineActivityState.enabled;

  const renderToggleIfNeeded = (toggle : boolean) => {
      if(!toggle) return null;
      return (
        <SwitchComponent
          onChange={handleRoutineActivity}
          checked={enabled}
          disabled={isUpdating}
        />
      )
  }

  const renderTimeSection = () => {
    if(enabled) return (<TimeSetter type={type} routine={routine}/>);

    return (<i className={styles.offNote}>{t(`card.preferences.routines.off`)}</i>);
  }
  return (
    <>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>{t(`card.preferences.routines.${type}.title`)}</div>
        {renderToggleIfNeeded(type === RoutineType.evening)}
      </div>
      {renderTimeSection()}
    </>
  )
}
export default RoutineSettings;
