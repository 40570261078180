import Route from "route-parser";
import {AppRoutes} from "./AppRoutes";
import {AppPage} from "./models";
import {RouteProps} from "react-router-dom";

export type DeepLinkRoute = {
  page : AppPage,
  route : Route,
  params : any,
  path : string,
  query?: { [key: string]: string }
}

const convertDeepLinkUrlIfNeeded = (origUrl : string) =>{
  if(!origUrl.startsWith(process.env.REACT_APP_DEEP_LINK_PREFIX)) return origUrl;

  return origUrl.replace(`${process.env.REACT_APP_DEEP_LINK_PREFIX}://`, `${process.env.REACT_APP_APP_BASE_PATH}/`);
}

export function locateDeepLinkAppRoute(origUrl : string) : DeepLinkRoute | null {
  const url = convertDeepLinkUrlIfNeeded(origUrl);

  const { pathname: path, searchParams: query } = new URL(url);

  return locatePathAppRoute(path, query);
}

export function locatePathAppRoute(path : string, query?: URLSearchParams) : DeepLinkRoute | null {
  const mappedRoutes = AppRoutes.map(pageRoute => {
    const route = pageRoute.routes.find((route: RouteProps) => {
      const routeMatcher = new Route(route.path as string)
      return routeMatcher.match(path)
    });

    if (!route) return null;

    const routeObj = new Route(route.path as string)
    const deepLinkRoute: DeepLinkRoute = {
      page: pageRoute.page,
      route: routeObj,
      params: routeObj.match(path),
      path: path,
      query: query ? Object.fromEntries(query.entries()) : {},
    }

    return deepLinkRoute;
  }).filter(route => route);

  if (mappedRoutes.length) return mappedRoutes[0];

  return null;
}
