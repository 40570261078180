import React from "react";
import Card from "../Card";
import {ContentCardResponse} from "./ContentCardResponse";
import ContentSwiper from "./ContentSwiper";

type Props = {
  card : ContentCardResponse,
}

const ContentCard : React.FC<Props> =({card})=> {
  return (
    <Card backgroundColor={card.backgroundColor} title={card.title}>
      <ContentSwiper contentEntries={card.contentEntries}
                     contentColor={card.contentColor}
                     actionsColor={card.actionsColor}
                     paginatorColor={card.paginatorColor}
                     paginatorSelectedColor={card.paginatorSelectedColor}
      />
    </Card>
  )
}

export default ContentCard;
