import {
  LOGOUT_USER,
  SET_APP_CONFIG,
  SET_APP_CONTEXT,
  SET_AUTH_TOKEN,
  SET_AUTO_START_EXERCISE,
  SET_CARD_DATA,
  SET_DEEP_LINK_ROUTE,
  SET_FILTER, SET_ACTIVATION, SET_SHOW_PAYWALL,
  SET_TAB_DATA,
  SET_USER, SET_PENDING_ACTIVATION_TOKEN
} from "./actionTypes";
import {UserStateInfo} from "./reducers";
import {DeepLinkRoute} from "../NativeApp/routing/app-deep-link-route-locator";
import {AppContext} from "../common/AppContext";
import {AppConfigResponse} from "../NativeApp/hooks/useAppConfig";

export const setFilter = (filter: string) => ({ type: SET_FILTER, payload: { filter } });
export const setAuthToken = (token : string) => ({type : SET_AUTH_TOKEN, payload : token })
export const setUserState = (user : UserStateInfo) => ({type : SET_USER, payload : user })
export const setTabData = (tab : string, data : any) => ({type : SET_TAB_DATA, payload : {tab : tab, data : data}})
export const setCardData = (card : string, data : any) => ({type : SET_CARD_DATA, payload : {card : card, data : data}})

export const setDeepLinkRoute = (route : DeepLinkRoute) => ({type : SET_DEEP_LINK_ROUTE, payload : {route : route}})
export const setAutoStartExercise = (exercise : DeepLinkRoute) => ({type : SET_AUTO_START_EXERCISE, payload : {autoStartExercise : exercise}})

export const setAppContext = (context : AppContext) => ({type : SET_APP_CONTEXT, payload : {context : context}})
export const setAppConfig = (config : AppConfigResponse) => ({type : SET_APP_CONFIG, payload : {config : config}})

export const setShowPaywall = (showPaywall : boolean) => ({type : SET_SHOW_PAYWALL, payload : {showPaywall : showPaywall}})
export const setShowActivation = (activation : boolean) => ({type : SET_ACTIVATION, payload : {activation}});

export const setPendingActivationToken = (pendingActivationToken: string) => ({type : SET_PENDING_ACTIVATION_TOKEN, payload : {pendingActivationToken : pendingActivationToken}});
export const resetPendingActivationToken = () => ({type : SET_PENDING_ACTIVATION_TOKEN, payload : {pendingActivationToken : ''}});
export const setLogoutUser = () => ({type : LOGOUT_USER })
