import React from "react";
import styles from "./ValuePropSlide.module.css"

export type ValuePropSlideProps = {
  icon : any,
  title : string,
  text : string,
}

export const ValuePropSlide = ({ title, text, icon } : ValuePropSlideProps) => {
  const lines = text.split("\n");
  return (
    <div className={styles.container}>
        <div className={styles.imageContainer}> <img src={icon} alt={title}/> </div>

        <div className={styles.slideContent}>
          <div className={styles.title}>{title}</div>
          <div className={styles.text}>
            {lines.map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </div>
        </div>
    </div>
  )
}
