import {Exercise} from "./model";
import React from "react";
import styles from "./ExersiceItem.module.css"
import {playExerciseMedia} from "./exercise_media_player";

type Props = {
  item : Exercise
}

const ExerciseItem = ({item} : Props) => {

  return (
    <div className={styles.container} onClick={()=> playExerciseMedia(item)}>
      <div className={styles.contentContainer}>
        <div className={styles.infoContainer}>
          <div className={styles.title}>{item.title}</div>
          <div>
            <div className={styles.module} style={ {color: item.color, borderColor:item.color}}>{item.module}</div>
          </div>
        </div>
        <div className={styles.thumbnailContainer}>
          <img alt="" src={item.thumbnailUrl}/>
          <div className={styles.practiceLength}>{item.length}</div>
        </div>
      </div>
    </div>
  )
}

export default ExerciseItem
