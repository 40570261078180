import styles from "./AppHeader.module.css";
import React from "react";
import {DateTime} from "luxon";
import {
  Link
} from "react-router-dom";
import logo from "./assets/logo.svg"
import calendar from "../cards/WelcomeCard/assets/calendar.svg";
import {useLocale} from "../../locale";


export function AppHeader(props : any) {
    const helloHeader = props?.value ? "Hello, " + props.value.first_name : ""
    const currentDate = DateTime.now();
    const {formatDate} = useLocale();

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.logoContainer}>
                  <Link to={"/"}>
                    <img className={styles.logo} src={logo} alt="" />
                  </Link>
                </div>
                <div className={styles.date}>
                    <img className={styles.calendar} src={calendar} alt="" />
                    <div className={styles.dateText}>{formatDate(currentDate)}</div>
                </div>
            </div>
            <div className={styles.welcomeMessage}>{helloHeader}</div>
        </div>
    )
}
