import React, {useEffect} from "react";
import AnalyticsTracker from "../../../components/AnalyticsTracker";
import {IonContent} from "@ionic/react";
import {NativeChatView} from "./NativeChatViewPlugin";
import {getUser} from "../../../common/user-provider";
import {useHistory} from "react-router-dom";
import useNativeChat from "./useNativeChat";
import {registerNotifications} from "../../services/push-notifications";
import useAppContext from "../../../common/hooks/useAppContext";
import {ChatPageRoutes} from "./ChatPageRoutes";

const ChatTabPage = () => {
  const history = useHistory();
  const chatInitialized = useNativeChat();
  const user = getUser();
  const appContext = useAppContext();
  const chatDeepLink = new ChatPageRoutes().deepLinkPath;

  useEffect(() => {
    if (!user.chat || !chatInitialized || !appContext) return;
    registerNotifications().then();
    NativeChatView.openChat({
      channel: user.chat.channel,
      appContext: appContext
    }).then(() => {
      history.goBack();

      //For cases that the app was opened from cold start
      if(history.location.pathname === chatDeepLink){
        history.replace("/");
      }
    });
  }, [user, chatInitialized, appContext]);

  return (
    <IonContent>
      <AnalyticsTracker event={"App Chat Tab Loaded"}/>
    </IonContent>
  )
}

export default ChatTabPage;
