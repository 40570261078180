import axios from "axios";
import {UserTimezone, Journal, BasePayload} from "./models";
import store from "../store";
import {logoutUser} from "./user_authenticator";

export enum CallMethod {
  post = "post",
  get = "get",
  patch = "patch",
  delete = "delete"
}

type APIHeaders = {
  Authorization? : string,
  sid? : string,
  AppContext? : string
}

export enum APINamespace{
  api = "api",
  app = "app"
}

export interface APICallOptions{
  baseUrl? : string
  apiNamespace? : APINamespace
  data? : any
  config? : any
}

export async function callApi<T>(method : CallMethod, path : string, options? : APICallOptions): Promise<T>{
  const callOptions = {
    baseUrl : process.env.REACT_APP_API_URL,
    apiNamespace : APINamespace.api,
    ...(options || {})
  }

  const apiURL = `${callOptions.baseUrl}/${callOptions.apiNamespace}/${path}`;
  const token = store.getState().user?.token;

  const headers : APIHeaders = {}

  if(store.getState().user?.sourceId){
    headers.sid = store.getState().user?.sourceId;
  }

  if(token){
    headers.Authorization = `Basic ${token}`;
  }

  if(store.getState().appContext){
    headers.AppContext = JSON.stringify(store.getState().appContext)
  }

  const config = {
    method : method,
    url : apiURL,
    headers : headers,
    withCredentials : true,
    ...callOptions.config
  }

  if(callOptions.data){
    if(method === CallMethod.get){
      // @ts-ignore
      config["params"] = callOptions.data;
    }else{
      // @ts-ignore
      config["data"] = callOptions.data;
    }
  }

  console.log("Calling API:" +path + " with config:");
  console.log(config);

  try{
    const response = await axios(config);
    const responseData = response.data as BasePayload<T>;
    return responseData.response;
  }catch (error : any) {
    if(error?.response?.status === 401){
      await logoutUser();
      return null;
    }

    throw error;
  }
}

const getCardData = async<T> (populatePath: string) => {
  return callApi<T>(CallMethod.get, `homepage${populatePath}`);
};

const setTimezone = async (timezone: Partial<UserTimezone>) => {
  return callApi(CallMethod.patch, "user/timezone", { data : timezone });
};

const getJournal = async (minDate?: number) => {
  const params = minDate ? { timestamp: minDate } : {};
  return await callApi<Journal>(CallMethod.get, "journal/get", { data: params });
};

const addJournalEntry = async (description: string) => {
  return callApi(CallMethod.post, "journal", { data : { description }});
};

const deleteJournalEntry = async (id: string) => {
  return callApi(CallMethod.delete, `journal/${id}`);
};

const editJournalEntries = async (items: readonly { id: string; description: string }[]) => {
  return callApi(CallMethod.patch, `journal`, { data : {items}});
};

export default {
  getCardData,
  setTimezone,
  getJournal,
  addJournalEntry,
  deleteJournalEntry,
  editJournalEntries,
};
