import React from "react";
import GroupedJournalEntries from "../GroupedJournalEntries";
import styles from "./index.module.scss";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { setFilter } from "../../../store/actions";
import { filterItems } from "./items_filter";
import { Journal, User } from "../../../common/models";
import {
  IJournalFilter,
  JournalFiltersList,
} from "../JournalFilter/JournalFiltersList";
import useJournalHandler from "../common/useJournalHandler";
import { getKey } from "./utils";
import AddJournalEntryButton from "../AddJournalEntryButton";
import {InfiniteScroll} from "../../InfiniteScroll";
import useTranslation from "../../../locale/hooks/use-translation";

type Props = {
  activeJournalFilter: IJournalFilter;
  value: Journal;
  user: User;
  showAddEntryButton : boolean,
};

const JournalList = ({ value, activeJournalFilter, user, showAddEntryButton }: Props) => {
  const {
    journal,
    handleDeleteEntry,
    handleEditEntries,
    loadMoreJournalItems
  } = useJournalHandler(value);
  const {t} = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>{t("card.journal.title")}</div>
        {/*<JournalFilter />*/}
        {showAddEntryButton && <AddJournalEntryButton value={value} user={user}/>}

          {filterItems(journal.items, activeJournalFilter).map((item) => (
            <GroupedJournalEntries
              value={item}
              canEdit={activeJournalFilter === JournalFiltersList[0]}
              key={getKey(item)}
              onDelete={handleDeleteEntry}
              onEdit={handleEditEntries}
            />
          ))}
        </div>
      <InfiniteScroll threshold={100} loadMoreCallback={loadMoreJournalItems}/>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  activeJournalFilter: state.activeJournalFilter,
});

export default connect(mapStateToProps, { setFilter })(JournalList);
