import {useCallback} from "react";
import {atom, selector, useRecoilState, useRecoilValue} from "recoil";
import {recoilPersist} from "recoil-persist";
import {DateTime} from "luxon";
import {getLocaleByCode} from "../model/supported-locales";
import {ILocale} from "../model/locale";
import {DateFormat, getDateFormat} from "../model/date-formats";

interface ILocaleDateFormattingOptions {
    format: DateFormat;
}

interface IUseLocaleResult {
    locale: ILocale | null;
    direction: "rtl" | undefined;
    setLocale: (code: string) => void;
    formatDate(date: DateTime | Date | string, options?: ILocaleDateFormattingOptions): string;
}

const { persistAtom } = recoilPersist({
    key: 'current-locale',
});

const currentLocaleState = atom<ILocale | null>({
    key: 'current-locale-state',
    default: null,
    effects: [persistAtom],
});

const currentLocaleDirectionState = selector<'rtl' | undefined>({
    key: 'current-locale-direction-state',
    get: ({get}) => {
        const locale = get(currentLocaleState);
        return locale?.rtl === true ? 'rtl' : undefined;
    }
});

export default function useLocale() : IUseLocaleResult {
    const [currentLocale, setCurrentLocale] = useRecoilState<ILocale | null>(currentLocaleState);
    const localeDirection = useRecoilValue(currentLocaleDirectionState);

    const setLocaleByCode = useCallback((code: string) => {
        const locale = getLocaleByCode(code);
        if (!locale) {
            return;
        }

        setCurrentLocale(locale);
    }, [setCurrentLocale])

    const formatDate = useCallback((date: DateTime | Date | string, options?: ILocaleDateFormattingOptions) : string => {
        const dateFormat = getDateFormat(currentLocale, options?.format);
        if (date instanceof DateTime) {
            return date.toFormat(dateFormat, { locale: currentLocale?.shortCode });
        }

        if (date instanceof Date) {
            return DateTime.fromJSDate(date, { zone: 'utc'  }).toFormat(dateFormat, { locale: currentLocale?.shortCode });
        }

        if (typeof date === 'string') {
            return DateTime.fromISO(date, { zone: 'utc' }).toFormat(dateFormat, { locale: currentLocale?.shortCode });
        }

        return date;
    }, [currentLocale]);

    return {
        locale: currentLocale,
        direction: localeDirection,
        setLocale: setLocaleByCode,
        formatDate,
    };
}
