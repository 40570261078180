import React from "react";
import styles from "./index.module.css"

const TermsLinks = () => {
  return (
      <div className={styles.tos}>By continuing, you accept Kai's <a href={"https://kai.ai/terms/"}>Terms</a> and <a href={"https://kai.ai/privacy-policy/"}>Privacy Policy</a></div>
  )
}

export default TermsLinks;
