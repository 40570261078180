import {useEffect, useState} from "react";
import {callApi, CallMethod} from "../../common/api";
import {UserAuthResponse} from "../../common/models";
import store from "../../store";
import {setUserState} from "../../store/actions";
import {UserStateInfo} from "../../store/reducers";
import useWebUserStateHandler from "./useWebUserStateHandler";

export enum WebUserAuthState{
  notAuthenticated,
  authenticated,
  sessionExpired,
  error
}

const useWebAuthentication = () => {
  const [authenticationState, setAuthenticationState] = useState<WebUserAuthState>(WebUserAuthState.notAuthenticated);
  useWebUserStateHandler();

  useEffect(() => {
    callApi<UserAuthResponse>(CallMethod.get, `user/auth`)
      .then((authResponse) => {
        const userState: UserStateInfo = {
          token: authResponse.token,
          sourceId: authResponse.user.sourceId,
          userId: authResponse.user.userId,
          chat: authResponse.chat,
        }

        store.dispatch(setUserState(userState))
        setAuthenticationState(WebUserAuthState.authenticated);
      })
      .catch((error) =>{
        if (error.response?.status === 403) {
          const userState: UserStateInfo = {
            renewSessionLink : error.response?.data?.response?.renewLink
          }

          store.dispatch(setUserState(userState))
          setAuthenticationState(WebUserAuthState.sessionExpired);
          return;
        }

        setAuthenticationState(WebUserAuthState.error);
      })
  }, []);

  return authenticationState;
}

export default useWebAuthentication;
