import { registerPlugin } from '@capacitor/core';

import type { NativeChatViewPlugin } from './definitions';

const NativeChatView = registerPlugin<NativeChatViewPlugin>('NativeChatView', {
  web: () => import('./web').then(m => new m.NativeChatViewWeb()),
});

export * from './definitions';
export { NativeChatView };
