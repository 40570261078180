import React, { useEffect, useState, useMemo } from "react";
import styles from "./HappinessTracker.module.css";
import HapinessTrackeremptyState from "./assets/HapinessTrackeremptyState.png"
import HappinessChart from "./HappinessChart/index";
import useCardDataProvider, {CardDataProviderResultStatus} from "../useCardsDataProvider";
import {HappinessCardResponse} from "./HappinessCardResponse";
import WidgetCard from "../containers/WidgetCard";
import {DateFormat, useLocale} from "../../../locale";
import useTranslation from "../../../locale/hooks/use-translation";

type Props = {
  card : HappinessCardResponse
}

export function HappinessTracker({card } : Props) {
  const initialScores: any[] = [];
  const [timePeriodTitle, setTimePeriodTitle] = useState("");
  const [scores, setScores] = useState(initialScores);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {t} = useTranslation();
  const {formatDate} = useLocale();

  const cardDataResult = useCardDataProvider<HappinessCardResponse>(card.type, {
    populatePath: card.populatePath
  })

  useEffect(() => {
    if (!cardDataResult.data && cardDataResult.status === CardDataProviderResultStatus.loading) {
      setLoading(true)
      return;
    }

    if (!cardDataResult.data && cardDataResult.status === CardDataProviderResultStatus.failure) {
      setLoading(false)
      setError(true);
      return;
    }

    const response = cardDataResult.data;
    setLoading(false)
    setScores(response.scores)
    setTimePeriodTitle(`${formatDate(response.from, { format: DateFormat.INVERTED_RANGE  })} - ${formatDate(response.to, { format: DateFormat.INVERTED_RANGE  })}`);

  }, [cardDataResult, formatDate])

  const graphData = useMemo(() => {
    return scores.map(({date, value}) => ({
        x: date,
        y: value
      }));
  }, [scores]);

  const averageScore = useMemo(() => {
    if ((graphData || []).length === 0) return 0;
    let sum = graphData.reduce((sum, cur) => (+cur.y + sum), 0);
    return (sum / graphData.length).toFixed(2);
  }, [graphData]);

  const renderEmptyState = () => {
    return (
      <div style={{textAlign: 'center'}}>
        <img className={styles.HapinessTrackeremptyState} src={HapinessTrackeremptyState} alt=""/>
        <div className="emptyStateLabel">
          {t('card.happiness-tracker.no-data')}
        </div>
      </div>
    )
  }

  const renderGraph = () => {
    console.log(timePeriodTitle);
    return (
      <div>
        <div className={styles.subTitle}>
          <strong>{ timePeriodTitle }</strong>
        </div>
        <div className={styles.graph}>
          <HappinessChart data={graphData}/>
        </div>
        <div className={styles.aggregate}>
          {`${t('card.happiness-tracker.average-score')}: ${averageScore}`}
        </div>
      </div>
    )
  }

  const renderErrorIndicator = () => {
    return (<div>Error</div>);
  }

  const renderLoadingIndicator = () => {
    return (
      <div className="loading_wrapper">
        <div id="loading"></div>
      </div>
    )
  }

  return (
    <WidgetCard card={card}>
      {
        loading
          ? renderLoadingIndicator()
          : error
          ? renderErrorIndicator()
          : (graphData || []).length > 0
            ? renderGraph()
            : renderEmptyState()
      }
    </WidgetCard>
  )
}
