import * as React from 'react';

const EmojiPath = (props: any) => {
  const {status} = props;

  return (
    status === 1 
    ? (
      <>
        <path stroke="#403408" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.084" d="M4.444 5.877L6.667 7.365 4.462 8.84" transform="translate(-31 -577) translate(10 337) translate(21 99) translate(0 141.05)"/>
        <path stroke="#403408" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.072" d="M15.556 8.84L13.333 7.352 15.538 5.877" transform="translate(-31 -577) translate(10 337) translate(21 99) translate(0 141.05)"/>
        <path stroke="#403408" strokeLinecap="round" strokeWidth=".871" d="M5.926 14.025s.783-2.963 3.72-2.963c2.938 0 3.687 2.94 3.687 2.94" transform="translate(-31 -577) translate(10 337) translate(21 99) translate(0 141.05)"/>
      </>
    )
    : status === 2
      ? (
        <>
          <path fill="#403408" d="M5.185 10.333c-.407 0-.74-.305-.74-.677V7.307c0-.373.333-.677.74-.677.408 0 .741.304.741.677v2.349c0 .372-.333.677-.74.677M14.815 10.333c-.408 0-.74-.305-.74-.677V7.307c0-.373.332-.677.74-.677.407 0 .74.304.74.677v2.349c0 .372-.333.677-.74.677" transform="translate(-31 -541) translate(10 337) translate(21 99) translate(0 105.037)"/>
          <path stroke="#403408" strokeLinecap="round" strokeWidth=".871" d="M5.926 14.037s.783-2.963 3.72-2.963c2.938 0 3.687 2.94 3.687 2.94" transform="translate(-31 -541) translate(10 337) translate(21 99) translate(0 105.037)"/>
        </>
      )
    : status === 3
      ? (
        <>
          <path fill="#403408" d="M5.185 10.37c-.407 0-.74-.303-.74-.673V8.081c0-.37.333-.674.74-.674.408 0 .741.303.741.674v1.616c0 .37-.333.673-.74.673M14.815 10.37c-.408 0-.74-.303-.74-.673V8.081c0-.37.332-.674.74-.674.407 0 .74.303.74.674v1.616c0 .37-.333.673-.74.673" transform="translate(-31 -507) translate(10 337) translate(21 99) translate(0 71)"/>
          <path stroke="#403408" strokeLinecap="round" strokeWidth=".747" d="M4.444 12.593L15.556 12.593" transform="translate(-31 -507) translate(10 337) translate(21 99) translate(0 71)"/>
        </>
      )
    : status === 4
      ? (
        <>
          <path fill="#403408" d="M5.185 9.617c-.407 0-.74-.303-.74-.673V7.328c0-.37.333-.674.74-.674.408 0 .741.303.741.674v1.616c0 .37-.333.673-.74.673M14.815 9.617c-.408 0-.74-.303-.74-.673V7.328c0-.37.332-.674.74-.674.407 0 .74.303.74.674v1.616c0 .37-.333.673-.74.673" transform="translate(-31 -471) translate(10 337) translate(21 99) translate(0 35.012)"/>
          <path stroke="#403408" strokeLinecap="round" strokeWidth=".871" d="M14.074 12.58s-.783 2.963-3.72 2.963c-2.937 0-3.687-2.94-3.687-2.94" transform="translate(-31 -471) translate(10 337) translate(21 99) translate(0 35.012)"/>
        </>
      )
    : (
      <path stroke="#403408" strokeLinecap="round" strokeWidth=".871" d="M13.333 8.786s-.02-1.481 1.488-1.481 1.475 1.47 1.475 1.47M3.704 8.786s-.02-1.481 1.488-1.481 1.475 1.47 1.475 1.47M13.926 12.49s-.846 2.963-4.018 2.963c-3.172 0-3.982-2.94-3.982-2.94" transform="translate(-31 -436) translate(10 337) translate(21 99) translate(0 .103)"/>
    )
  )
}

export default EmojiPath;