import React from "react";
import Button, {ButtonStyle} from "../../components/Button/Button";
import styles from './Activation.module.css';
import {IonPage} from "@ionic/react";
import AnalyticsTracker from "../../../components/AnalyticsTracker";
import logo from "../../Onboarding/assets/logo.svg";
import useActivation from "../../hooks/useActivation";

export default function ActivationPage() {
  const [personalizedExperience, setPersonalizedExperience] = React.useState(false);
  const { activateUser } = useActivation();

  const hasLinkClick = () => {
    setPersonalizedExperience(true);
  };

  const continueWithoutLinkClick = async () => {
    await activateUser();
  };

  return (
    <IonPage>
      <AnalyticsTracker event={"Activation Page Loaded"}/>
      <div className={styles.container}>
        <div className={styles.content}>
          <img src={logo} alt={"logo"} className={styles.logo}/>
            {!personalizedExperience &&
              <div className={styles.bottom}>
                <div className={styles.title}>Welcome</div>
                <div>
                  <p className={styles.text}>
                    After a quick setup, you’ll be able to start chatting with Kai to support your well-being.
                  </p>
                  <p className={styles.boldText}>
                    If you have received an invite link, it’s important to use it so the personalized version of Kai
                    designed just for you gets activated.
                  </p>
                </div>
                <div className={styles.buttonsContainer}>
                  <Button label={"I got an invite link"} style={ButtonStyle.main} onClick={hasLinkClick}/>
                  <Button label={"Continue without an invite link"} style={ButtonStyle.link}
                          onClick={continueWithoutLinkClick}/>
                </div>
              </div>
            }

          {personalizedExperience &&
            <div className={styles.bottom}>
              <div className={styles.title}>Activating your Kai</div>
              <div>
                <p className={styles.text}>
                  Please tap the invite link that was shared with you (here on your phone, not on desktop!).
                </p>
                <p className={styles.text}>
                  The link will bring you back to this app, and your personalized version of Kai will be activated.
                </p>
              </div>
              <div className={styles.buttonsContainer}>
                <Button label={"Continue without an invite link"} style={ButtonStyle.link}
                        onClick={continueWithoutLinkClick}/>
              </div>
            </div>
          }
        </div>
      </div>
    </IonPage>
  )
}
