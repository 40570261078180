import {useEffect} from "react";
import {trackEvent} from "../../common/analytics_tracker";

type Props = {
  event : string,
  params? : any
}

const AnalyticsTracker = ({event , params} : Props) : any => {

  useEffect(()=>{
    trackEvent(event, params);
  }, [])

  return null
}

export default AnalyticsTracker;
