import React from "react";
import styles from "./index.module.css"

type Props = {
  selectedIndex : number,
  numElements : number,
  backgroundColor : string,
  selectedColor : string
}

const PaginationIndicator = ({selectedIndex, numElements, backgroundColor, selectedColor} : Props) => {
  const renderDots = (selectedIndex : number, numElements : number, regularColor : string, activeColor : string) => {
    const dotsDivs = [];
    for(let i = 0; i < numElements; i++){
      const backgroundColor = i === selectedIndex
        ? activeColor
        : regularColor

       dotsDivs.push(<div className={styles.dot} style={{backgroundColor : backgroundColor}} key={i}> </div>)
    }

    return dotsDivs;
  }
  return (
    <div className={styles.container}>
      {renderDots(selectedIndex, numElements, backgroundColor, selectedColor)}
    </div>
  )
}

export default PaginationIndicator;
