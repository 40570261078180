export enum CardsType {
  welcomeCard = "welcome_card",
  helloUserCard = "hello_user_card",
  journalingStatsCard = "journaling_stats_card",
  userPreferencesCard = "user_preferences_card",
  happinessCard = "happiness_card",
  happinessScoreCard = "happiness_score_card",
  journalTrackerCard = "journal_tracker_card",
  topTopicsCard = "top_topics_card",
  topicsWordCloudCard = "topics_word_cloud_card",
  userJourneyCard = "user_journey_card",
  journalsCard = "journals_card",
  contentCard = "content_card",
  inviteFriendsCard = "invite_friends_card",
  accountCard = "account_card",
}

export type CardResponse = {
  type? : CardsType;
  populated? : boolean;
  populatePath? : string;
  title? : string;
  titleKey?: string;
}
