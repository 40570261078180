
export enum InfiniteScrollFetchStatus{
  loading = "loading",
  error = "error",
  success = "success"
}

export interface InfiniteScrollFetchResult{
  status : InfiniteScrollFetchStatus;
  hasMore : boolean;
}
