import {trackEvent} from "../../../common/analytics_tracker";
import {StreamingMedia, StreamingVideoOptions} from "@ionic-native/streaming-media";
import {Exercise} from "./model";


export const playExerciseMedia = (item : Exercise) => {
  trackEvent("Exercise Started", {name:item.title, module:item.module});

  const options: StreamingVideoOptions = {
    successCallback: () => {
      trackEvent("Exercise Completed", {name:item.title, module:item.module});
    },
    errorCallback: (e: any) => {
      console.error('Error streaming', e)
    },
    orientation: 'horizontal',
    shouldAutoClose: true,
    controls: true
  };

  console.log(item.mediaUrl)
  StreamingMedia.playVideo(item.mediaUrl, options);
}
