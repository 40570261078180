import {AppPage} from "../../routing/models";
import PracticePageTab from "./PracticePageTab";
import {AppPageRoutes} from "../../routing/AppRoutes";

export class PracticePagerRoutes implements AppPageRoutes {
  page = AppPage.practice;
  component = PracticePageTab;
  routes = [{
    path: "/:tab(practice)/",
    exact: true
  }, {
    path: "/:tab(practice)/:exerciseName"
  },
    {
      path: "/practice",
      exact: true
    }]
  deepLinkPath = "/practice"
}
