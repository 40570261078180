
export enum AppPlatform{
  iOS = "ios",
  android = "android",
  web = "web"
}

export type AppContext = {
  appVersionNumber : string,
  appVersionCode : number,

  deviceModel : string,
  osVersion : string,
  platform : AppPlatform,

  deviceId : string
  timezone : string
}
