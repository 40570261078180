import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";


export default function useQuery<T extends Record<string, string>>(): T {
  const location = useLocation();
  const [query, setQuery] = useState<T>({} as T);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query: Record<string, string> = {};

    searchParams.forEach((value, key) => {
      query[key] = value;
    });

    setQuery(query as T);
  }, [location.search]);

  return query;
}
