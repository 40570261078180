import React, { useMemo, useState } from "react";
import styles from "./index.module.scss";
import { format, MAX_CHAR } from "./utils";
import useTranslation from "../../../locale/hooks/use-translation";

type Props = {
  content: string;
};

const Readonly = ({ content }: Props) => {
  const [isExpanded, setExpanded] = useState(false);
  const shouldTrim = useMemo(() => {
    return content.length > MAX_CHAR;
  }, [content]);
  const {t} = useTranslation();

  return (
    <div className={styles.readonlyContainer}>
      {format(content, !isExpanded)}
      {shouldTrim && !isExpanded && (
        <span
          onClick={() => setExpanded(!isExpanded)}
          className={styles.moreLink}
        >
          {t("card.journal.show-more")}
        </span>
      )}

      {shouldTrim && isExpanded && (
        <span
          onClick={() => setExpanded(!isExpanded)}
          className={styles.moreLink}
        >
          {t("card.journal.show-less")}
        </span>
      )}
    </div>
  );
};

export default Readonly;
