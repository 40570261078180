import React from 'react';
import {selectUserPreferences} from '../../../../../store/selectors';
import {useSelector} from 'react-redux';
import styles from './index.module.scss';
import {SettingsItem} from "./SettingsItem";

export default function SettingsSection() {
  const userPreferences = useSelector(selectUserPreferences);

  return (
    <div className={`${styles.root}`}>
      {
        userPreferences?.settings?.map((setting, index) => (
          <SettingsItem key={index} {...setting} />
        ))
      }
    </div>
  )
}
