import React, {ReactNode, useEffect} from "react";
import {useLocale} from "../";
import useQuery from "../../MobileWebApp/hooks/useQuery";
import TranslationProvider from "./translation-provider";
import StylingProvider from "./styling-provider";

interface ILocaleProviderProps {
  children: ReactNode;
}

type LocaleParams = {
  locale: string;
}

export default function LocaleProvider({ children }: ILocaleProviderProps) {
  const { setLocale } = useLocale();
  const query = useQuery<LocaleParams>();

  useEffect(() => {
    console.log(`Locale: ${query?.locale}`);
    if (!query?.locale) return;

    setLocale(query.locale);
  }, [setLocale, query]);

  return (
    <StylingProvider>
      <TranslationProvider>
        {children}
      </TranslationProvider>
    </StylingProvider>);
}
