import React, {useCallback} from "react";
import styles from "./index.module.css";
import {AccountCardResponse} from "./account-card-response";
import WidgetCard from "../containers/WidgetCard";
import Button, {ButtonStyle} from "../../../NativeApp/components/Button/Button";
import {Dialog} from "@capacitor/dialog";
import {logoutUser} from "../../../common/user_authenticator";
import useAccountDeletion from "../../../NativeApp/hooks/useAccountDeletion";

type Props = {
  card : AccountCardResponse
}

const AccountCard = ({ card } : Props) => {
  return (
    <WidgetCard card={card}>
        <LogoutSection />
        <div className={styles.separator}> </div>
        <DeleteAccountSection />
    </WidgetCard>
  )
}

const LogoutSection = () => {
  const logout = useCallback(async () => {
    const response = await Dialog.confirm({
      title: "Confirm logout",
      message: "Are you sure you want to logout?",
      okButtonTitle: "Yes",
      cancelButtonTitle: "No"
    });

    if(response.value){
      await logoutUser();
    }
  }, []);

  return (
    <>
      <div className={styles.title}>Log out</div>
      <div className={styles.text}>You can always come back :)</div>
      <Button label={"Log out from my account"} style={ButtonStyle.secondary} onClick={() => {void logout()}} />
    </>
  )
}

enum DeletionConfirmationState {
  cancelled,
  failedConfirmation,
  confirmed,
}

const DeleteAccountSection = () => {
  const { deleteAccount } = useAccountDeletion();

  const verifyDeletion = useCallback(async () => {
    const response = await Dialog.prompt({
      title: "Confirm account deletion",
      message: "Type DELETE to confirm account deletion",
    });

    if(response.cancelled) return DeletionConfirmationState.cancelled;
    if(response.value.toLowerCase() === "delete") return DeletionConfirmationState.confirmed;
    return DeletionConfirmationState.failedConfirmation;
  }, []);

  const confirmAccountDeletion = useCallback(async () => {
    let askForVerification = true;
    do{
      const result = await verifyDeletion();
      switch (result) {
        case DeletionConfirmationState.cancelled:
          askForVerification = false;
          break;
        case DeletionConfirmationState.confirmed:
          askForVerification = false;
          await deleteAccount();
          await logoutUser();
          break;
        case DeletionConfirmationState.failedConfirmation:
          askForVerification = true;
      }
    }while (askForVerification)
  }, [verifyDeletion]);

  return (
    <>
      <div className={styles.title}>Delete account</div>
      <div className={styles.text}>Are you sure? This action is permanent.</div>
      <Button label={"Delete my account"} style={ButtonStyle.danger} onClick={() => {void confirmAccountDeletion()}} />
    </>
  )
}

export default AccountCard;
