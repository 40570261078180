import {AppPage} from "../../routing/models";
import {AppPageRoutes} from "../../routing/AppRoutes";
import JournalsTabPage from "./JournalsTabPage";


export class JournalsPageRoutes implements AppPageRoutes{
    page = AppPage.journals;
    component = JournalsTabPage;
    routes = [{
        path : "/journals",
        exact : true
      }
    ]
  deepLinkPath = "/journals"
}
