import {MessageUIComponentProps} from "stream-chat-react";

export enum ButtonTypes{
  quickReply = "quick_reply",
  postback = "postback",
  link = "link"
}

export interface MessageButtonResponse{
  id : string,
  type : ButtonTypes,
  label : string,
  payload : string
}

export interface MessageResponse extends MessageUIComponentProps{
  buttons? : MessageButtonResponse[]
}

export enum EventTypes{
  buttonClicked = "button_clicked",
}
