import React from "react";
import styles from "./styles.module.scss";
import ModalPortal from "../ModalPortal";

type Props = {
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const ConfirmationModal = ({ message, onCancel, onConfirm }: Props) => (
  <ModalPortal>
    <div className={styles.overlay} onClick={onCancel} />
    <div className={styles.panel}>
      <p>{message}</p>
      <div className={styles.buttons}>
        <button onClick={onCancel} className={styles.button}>
          Cancel
        </button>
        <button onClick={onConfirm} className={styles.button}>
          OK
        </button>
      </div>
    </div>
  </ModalPortal>
);

export default ConfirmationModal;
