import React, {useEffect} from "react";
import styles from "./ValueProposition.module.css"
import AnalyticsTracker from "../../../../components/AnalyticsTracker";
import "./ValueProposition.css"
import {IonPage} from "@ionic/react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import Button, {ButtonStyle} from "../../../components/Button/Button";

import chatIcon from "./assets/chat.svg";
import eyeIcon from "./assets/eye.svg";
import fingerprintIcon from "./assets/fingerprint.svg";
import lotusIcon from "./assets/lotus.svg";
import logo from "../../assets/logo.svg";
import appleLogo from "./assets/apple.svg";
import googleLogo from "./assets/google.svg";

import {ValuePropSlide} from "./ValuePropSlide/ValuePropSlide";
import {useHistory} from "react-router-dom";
import SwiperCore, { Pagination } from 'swiper';
import {useOAuthSignInEventsListeners} from "../../OAuthSignIn/useOAuthSignInEventsListeners";
import TermsLinks from "../../components/TermsLinks";
import {OAuthPlugin} from "../../OAuthSignIn/OAuthPlugin";
import useAppContext from "../../../../common/hooks/useAppContext";
import {AppPlatform} from "../../../../common/AppContext";

SwiperCore.use([Pagination]);

const ValueProposition = () => {
  const history = useHistory();

  const appContext = useAppContext()

  useOAuthSignInEventsListeners();

  useEffect(() => {}, [appContext]);

  const handleGetStarted = () => {
      history.push("/connect");
  }

  const handleAppleSignIn = async () => {
    await OAuthPlugin.signIn({});
  }

  const handleGoogleSignIn = async () => {
    await OAuthPlugin.signIn({
      google : {
        clientId : process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID
      }
    });
  }

  const renderButtons = () => {
    if(!appContext) return null;

    if(appContext.platform === AppPlatform.iOS) {
        return (
          <>
            <Button label={"SIGN IN WITH APPLE"} style={ButtonStyle.main} icon={appleLogo} onClick={() => {void handleAppleSignIn();}} />
            <Button label={"I'm already talking with Kai"} style={ButtonStyle.secondary} onClick={() => {handleGetStarted();}} />
          </>
        )
    }

    if(appContext.platform === AppPlatform.android) {
      return (
        <Button label={"SIGN IN WITH GOOGLE"} style={ButtonStyle.main} icon={googleLogo} onClick={() => {void handleGoogleSignIn();}} />
      )
    }

    return null;

  }
  return (
    <IonPage>
      <AnalyticsTracker event={"App Open"}/>
      <AnalyticsTracker event={"App ValueProposition Screen Loaded"}/>
      <div className={styles.container}>
        <div className={styles.bottomBg}> </div>
        <div className={styles.content}>
          <div className={styles.logoContainer}>
            <img src={logo} alt={"logo"} className={styles.logo}/>
          </div>
          <div className={styles.slidesContainer}>
          <Swiper initialSlide={0} speed={400} pagination={{ clickable: false }} className={styles.swiper}>
            <SwiperSlide>
              <ValuePropSlide title={"Discover"}
                              text={"What makes you who you are and how\nto live the life you really want."}
                              icon={fingerprintIcon}/>
            </SwiperSlide>
            <SwiperSlide>
              <ValuePropSlide title={"Chat"}
                              text={"With your own techno-spirit…\nA steady voice in the chaos.\nAn AI that helps you explore life’s questions that are right for you."}
                              icon={chatIcon}/>
            </SwiperSlide>
            <SwiperSlide>
              <ValuePropSlide title={"Receive"}
                              text={"Stress management tools along the way, when you need them.\nKai is rooted in decades of real and proven psychology frameworks."}
                              icon={lotusIcon}/>
            </SwiperSlide>
            <SwiperSlide>
              <ValuePropSlide title={"Keep tight"}
                              text={"Your self-discovery insights.\nLike magic, Kai organizes and makes sense of your reflections. Get it all out without having to track it down."}
                              icon={eyeIcon}/>
            </SwiperSlide>
          </Swiper>
          </div>
          <div className={styles.buttonContainer}>
            {renderButtons()}
            <TermsLinks />
          </div>
        </div>
      </div>
    </IonPage>
  )
}

export default ValueProposition;
