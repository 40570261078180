import React, { useState } from "react";
import styles from "./index.module.scss";
import AddEntryForm from "../AddEntryForm";
import analytics from "../../../common/journal_analytics";
import { Journal, User } from "../../../common/models";
import useJournalHandler from "../common/useJournalHandler";
import useTranslation from "../../../locale/hooks/use-translation";

type Props = {
  value: Journal;
  user: User;
};

const AddJournalEntryButton = ({ value, user }: Props) => {
  const [isFormOpen, setFormOpen] = useState(false);
  const {t} = useTranslation();

  const {
    handleAddEntry,
  } = useJournalHandler(value);

  const openNewEntry = () => {
    analytics.journal.entryClicked();
    setFormOpen(true);
  };

  const closeNewEntry = () => {
    analytics.journal.entryCancelled();
    setFormOpen(false);
  };

  const addNewEntry = async (description: string) => {
    await handleAddEntry(description);
    analytics.journal.entryCreated(description);
    setFormOpen(false);
  };

  return (
    <>
      {isFormOpen && (
        <AddEntryForm
          name={user.first_name}
          onClose={closeNewEntry}
          onSubmit={addNewEntry}
        />
      )}
      <button onClick={openNewEntry} className={styles.addEntry}>
        {t("card.journal.new-entry")}
      </button>
    </>
  );
};

export default AddJournalEntryButton;
