import {PaywallView} from "./PaywallViewPlugin";
import {Dialog} from "@capacitor/dialog";
import {SubscriptionPurchaseStatus, validateSubscriptionPurchase} from "./subscription-purchase-validator";
import {PlanResponse} from "../../../components/cards/UserPreferencesCard/UserPreferencesCardResponse";

export async function handlePurchaseCompletedEvent(dispatch : any, showLoading : any, hideLoading : any, userPreferences : any) {
  await PaywallView.hidePaywall();

  showLoading({
    message : "Hang tight, we're updating your subscription. This might take a minute."
  });

  await validateSubscriptionPurchase(async (status : SubscriptionPurchaseStatus, plan : PlanResponse) => {
    if(status == SubscriptionPurchaseStatus.validating) {
      return;
    }

    if (status == SubscriptionPurchaseStatus.success) {
      hideLoading();
      await handleSuccessfulPurchase(plan, dispatch, userPreferences);
      return;
    }

    if (status == SubscriptionPurchaseStatus.failure) {
      hideLoading();
      await handlePurchaseValidationFailedEvent();
      return;
    }
  });
}

async function handlePurchaseValidationFailedEvent() {
  await Dialog.alert({
    title: "Subscription Update",
    message: "Oops! We couldn't process your subscription right now. But don't worry, as soon as it's validated, it'll be all set on your account. Thanks for your patience!"
  });
}

async function handleSuccessfulPurchase(plan : PlanResponse, dispatch : any, userPreferences : any) {
  await Dialog.alert({
    title: "Subscription Activated!",
    message: "You're all set! Your subscription is now live. Enjoy!"
  });

  dispatch({
    type : "SET_USER_PREFERENCES",
    payload : {
      ...userPreferences,
      plan : plan
    }
  });

  return;
}
