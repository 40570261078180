import React from "react";

const DoneIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    viewBox="0 0 17 16"
    {...props}
  >
    <g stroke="#FF744F" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path
        fill="#FF744F"
        fillRule="nonzero"
        strokeWidth={0.5}
        d="M15.1 13.955a.502.502 0 01-.513.508H2.193a.51.51 0 01-.518-.508V2.193a.518.518 0 01.518-.518H8.5A.338.338 0 008.5 1H2.193h0A1.19 1.19 0 001 2.193v11.762a1.184 1.184 0 001.193 1.183h12.394a1.18 1.18 0 001.188-1.183V7.188a.337.337 0 10-.675 0v6.767h0z"
      />
      <path
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M4.574 8.069L7.54 11l7.865-9.273"
      />
    </g>
  </svg>
);

export default DoneIcon;
