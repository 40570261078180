import {useChatClient} from "./useChatClient";
import {getUser} from "../../common/user-provider";
import {useEffect, useState} from "react";
import {Channel} from "stream-chat";


export const useChatChannel = () => {
  const [channel, setChannel] = useState<Channel>(null);

  const chatClient = useChatClient();
  const user = getUser();

  useEffect(() => {
    if (!chatClient || !user.chat) return;

    const channel = chatClient.channel('messaging', user.chat.channel, {
      members: [user.userId],
    });

    setChannel(channel);
  }, [chatClient, user.chat]);

  return channel;
}
