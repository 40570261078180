import React, {useState} from "react";
import styles from "./index.module.css"
import {ContentResponse} from "../ContentCardResponse";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import ExternalShare from "../../../../ExternalShare";
import PaginationIndicator from "../../../../PaginationIndicator";
import {trackEvent} from "../../../../../common/analytics_tracker";

type Props = {
  contentEntries : ContentResponse[],
  contentColor : string,
  actionsColor : string,
  paginatorColor : string,
  paginatorSelectedColor : string
}

const ContentSwiper : React.FC<Props> =({contentEntries, contentColor, actionsColor, paginatorColor, paginatorSelectedColor})=> {
  const [selectedContentIndex, setSelectedContentIndex] = useState<number>(0);
  const [renderSwiper] = useState<boolean>(true)

  setTimeout(()=>{
    //setRenderSwiper(true)
  }, 50)

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {renderSwiper &&
        <Swiper loop={true}
                observer={true}
                observeParents={true}
                observeSlideChildren={true}
                spaceBetween={0}
                slidesPerView={'auto'}
                watchOverflow={true}
                initialSlide={selectedContentIndex}
                onActiveIndexChange={(swiper : any) =>{
                  setSelectedContentIndex(swiper.activeIndex % contentEntries.length)
                  trackEvent("Content Swiped", {
                    index : selectedContentIndex,
                    type : contentEntries[selectedContentIndex].type
                  })
                }}
        >
          {
            contentEntries.map((contentEntry: ContentResponse, index: number) => {
              return (
                <SwiperSlide key={index}>
                  <div className={styles.contentContainer}>
                  <div className={styles.text} style={{color: contentColor}}>{contentEntry.text}</div>
                  {
                    contentEntry.author
                      ? <div className={styles.author} style={{color: contentColor}}>--{contentEntry.author}</div>
                      : null
                  }
                  </div>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
        }
      </div>
      <div className={styles.footer}>
        <div className={styles.pagination}>
          <PaginationIndicator
            backgroundColor={paginatorColor}
            selectedColor={paginatorSelectedColor}
            numElements={contentEntries.length}
            selectedIndex={selectedContentIndex}/>
        </div>
        <div className={styles.actions}>
          <ExternalShare
            color={actionsColor}
            options={contentEntries[selectedContentIndex].shareOptions}
            trackData={{
              contentId : contentEntries[selectedContentIndex].id
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(ContentSwiper)
