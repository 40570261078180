import {useEffect} from "react";
import {PushNotifications, Token} from "@capacitor/push-notifications";
import {Preferences} from "@capacitor/preferences";
import {publishEvent} from "../AppEventsPublisher/app-event-publisher";
import {
  createPushTokenCreationErrorEvent,
  createPushTokenRegisteredEvent
} from "../AppEventsPublisher/events/push-notifications-app-events";
import {trackEvent} from "../../../common/analytics_tracker";
import {ActionPerformed} from "@capacitor/push-notifications/dist/esm/definitions";
import {usePlatform} from "../../../common/hooks/usePlatform";
import {AppPlatform} from "../../../common/AppContext";
import { useDeeplinkNavigate } from "../../hooks/useNavigate";

export const PushNotificationsEventsListeners = () : any => {
  const platform = usePlatform()
  const { navigate } = useDeeplinkNavigate();

  useEffect(()=>{
      const registerListeners = async () => {
        await PushNotifications.addListener('registration', async (token : Token) => {
          await Preferences.set({
            key: "push_token",
            value: token.value,
          });

          if(platform === AppPlatform.android) {
            await PushNotifications.createChannel({
              id: "kai",
              name: "Kai",
            })
          }
          await publishEvent(createPushTokenRegisteredEvent(token.value));
          trackEvent("Push Token Registered", {token: token.value});
        });

        await PushNotifications.addListener('registrationError', async (err : any) => {
          await createPushTokenCreationErrorEvent(err);
          trackEvent("Push Token Registration Error", {error: err});
        });

        await PushNotifications.addListener('pushNotificationActionPerformed', async (action: ActionPerformed) => {
          trackEvent("App Opened from Push Notification", {notification : action.notification.data});
          const deepLink = action?.notification?.data?.deepLink ??
            action?.notification?.data?.data?.deepLink;

          if(!deepLink) return;

          await navigate(deepLink);
        });
      }

    registerListeners().then();
  }, [])

  return null
}
