import {ILocale} from "./locale";
import {DEFAULT_LOCALE} from "./supported-locales";

export enum DateFormat {
    FULL_DATE = 'full',
    PARTIAL_DATE = 'partial',
    DAY_OF_WEEK = 'day-of-week',
    RANGE = 'range',
    INVERTED_RANGE = 'inverted-range',
    MONTH = 'month',
}

const MAP: { [key:string]: { [key:string]: string }} = {
    "en_US": {
        [DateFormat.FULL_DATE]: 'LLLL d, yyyy',
        [DateFormat.PARTIAL_DATE]: 'LLL d, yyyy',
        [DateFormat.DAY_OF_WEEK]: 'ccc, LLLL dd',
        [DateFormat.RANGE]: 'dd LLL',
        [DateFormat.INVERTED_RANGE]: 'LLL dd',
        [DateFormat.MONTH]: 'LLLL yyyy',
    },
    "he_IL": {
        [DateFormat.FULL_DATE]: 'dd בLLLL yyyy',
        [DateFormat.PARTIAL_DATE]: 'dd בLLL yyyy',
        [DateFormat.DAY_OF_WEEK]: 'ccc, dd בLLLL',
        [DateFormat.RANGE]: 'dd בLLL',
        [DateFormat.INVERTED_RANGE]: 'dd בLLL',
        [DateFormat.MONTH]: 'LLLL yyyy',
    }
}

export function getDateFormat(locale: ILocale, format?: DateFormat) : string {
    const code = locale?.code || DEFAULT_LOCALE;
    return MAP[code][format || DateFormat.PARTIAL_DATE];
}
