import React, { useState, useEffect } from 'react';
import LoginPageResponseContext from './LoginPageResponseContext';
import { callApi, CallMethod } from '../../common/api';
import { LoginPageResponse } from './models/LoginPageResponse';

export const LoginPageResponseProvider = ({ children } : any) => {
  const [loginPageResponse, setLoginPageResponse] = useState<LoginPageResponse>(null);

  useEffect(() => {
    callApi<LoginPageResponse>(CallMethod.get, "page/login")
      .then((response : LoginPageResponse) => {
        setLoginPageResponse(response)
      })
  }, [])

  return (
    <LoginPageResponseContext.Provider value={loginPageResponse}>
      {children}
    </LoginPageResponseContext.Provider>
  );
};
