import React, { ChangeEvent, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import styles from "./index.module.scss";
import BinIcon from "./BinIcon";
import ConfirmationModal from "../../ConfirmationModal";
import useTranslation from "../../../locale/hooks/use-translation";

type Props = {
  id: string;
  content: string;
  onDelete: (id: string) => void;
  onEdit: (id: string, content: string) => void;
};

const Editable = ({ id, content, onDelete, onEdit }: Props) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const {t} = useTranslation();

  const handleConfirm = () => {
    onDelete(id);
    setConfirmModalOpen(false);
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onEdit(id, event.target.value);
  };

  return (
    <>
      {confirmModalOpen && (
        <ConfirmationModal
          message={t("card.journal.delete-entry.confirmation")}
          onCancel={() => setConfirmModalOpen(false)}
          onConfirm={handleConfirm}
        />
      )}
      <div className={styles.editableContainer}>
        <TextareaAutosize onChange={handleChange} value={content} />
        <button
          className={styles.binButton}
          onClick={() => setConfirmModalOpen(true)}
        >
          <BinIcon />
        </button>
      </div>
    </>
  );
};

export default Editable;
