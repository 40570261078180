import React from 'react';
import {BrowserRouter as Router, Route, Switch, useLocation} from 'react-router-dom';
import ValueProposition from "./Pages/ValueProposition/ValueProposition";
import ConnectPage from "./Pages/Connect/ConnectPage";
import {LoginPageResponseProvider} from "./LoginPageResponseProvider";
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import "./OnboardingRouter.css"

const OnboardingRoutes = () => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames="fade"
        timeout={500}
      >
        <Switch location={location}>
          <Route exact path="/connect">
            <ConnectPage/>
          </Route>
          <Route>
            <ValueProposition/>
          </Route>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export const OnboardingRouter: React.FC = () => {
  return (
    <LoginPageResponseProvider>
      <Router>
        <OnboardingRoutes />
      </Router>
    </LoginPageResponseProvider>
  );
};
