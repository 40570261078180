import {PlanResponse} from "../../../components/cards/UserPreferencesCard/UserPreferencesCardResponse";
import {callApi, CallMethod} from "../../../common/api";

const MAX_TRIES = 10;
const WAIT_TIME = 3;

export enum SubscriptionPurchaseStatus{
  validating ,
  success,
  failure
}

export enum PurchaseValidationStatus{
  valid,
  notValid
}

interface SubscriptionPurchaseValidationResult {
  status : PurchaseValidationStatus,
  plan : PlanResponse
}

export async function validateSubscriptionPurchase(callback: any) {
  let tries = 0;

  const interval = setInterval(async () => {
    if (tries >= MAX_TRIES) {
      clearInterval(interval);
      await callback(SubscriptionPurchaseStatus.failure, null);
      return;
    }

    try {
      const response = await callApi<SubscriptionPurchaseValidationResult>(CallMethod.get, "subscription/purchase/validate");

      if (response.status == PurchaseValidationStatus.valid) {
        clearInterval(interval);
        await callback(SubscriptionPurchaseStatus.success, response.plan);
        return;
      }

      await callback(SubscriptionPurchaseStatus.validating, null);
    } catch (e) {
      await callback(SubscriptionPurchaseStatus.validating, null);
    } finally {
      tries++;
    }
  }, WAIT_TIME * 1000);
}
