import locked from './assets/locked.png'
import level1 from './assets/level-1.svg'
import level2 from './assets/level-2.svg'
import level3 from './assets/level-3.svg'
import level4 from './assets/level-4.svg'
import level5 from './assets/level-5.svg'
import level6 from './assets/level-6.svg'
import level7 from './assets/level-7.svg'
import level8 from './assets/level-8.svg'
import level9 from './assets/level-9.svg'

const JourneyLevelBadges: any = [
    locked,
    level1,
    level2,
    level3,
    level4,
    level5,
    level6,
    level7,
    level8,
    level9
]

export default JourneyLevelBadges;
