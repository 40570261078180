import React from "react";
import styles from "./index.module.scss";
import EditIcon from "./EditIcon";
import DoneIcon from "./DoneIcon";
import useTranslation from "../../../locale/hooks/use-translation";

type Props = {
  isEditing: boolean;
  onClick: () => void;
};

const EditButton = ({ isEditing, onClick }: Props) => {
  const {t} = useTranslation();

  return (<button
      className={`${styles.editButton} ${isEditing ? styles.activeButton : ""}`}
      onClick={onClick}
    >
      {isEditing ? (
        <>
          <DoneIcon/><span>{t('button.edit.done')}</span>
        </>
      ) : (
        <>
          <EditIcon/><span>{t('button.edit')}</span>
        </>
      )}
    </button>
  );
}

export default EditButton;
