import React, {useEffect} from 'react';
import {App} from '@capacitor/app';
import {trackEvent} from "../common/analytics_tracker";
import { useDeeplinkNavigate } from "./hooks/useNavigate";

type DeepLinkPayload = {
  url : string
}

const trackAppUrlOpen = (url : string) => {
  if (!url) return;

  const [fullUrl, params] = url.split("?");

  const parameters = params
    ? Object.fromEntries(new URLSearchParams(params))
    : {}

  const payload = {
    url: fullUrl.includes("login") ? "login" : fullUrl,
    ...parameters
  }

  trackEvent("App Opened From Deep Link", payload);
}

const AppUrlListener: React.FC<any> = () => {
  const { navigate } = useDeeplinkNavigate();

  useEffect(() => {
    App.addListener('appUrlOpen', async (payload: DeepLinkPayload) => {
      trackAppUrlOpen(payload.url);
      await navigate(payload.url);
    });
  }, [navigate]);

  return null;
};

export default AppUrlListener;
