import {useCallback} from "react";
import {callApi, CallMethod} from "../../common/api";
import {useLoading} from "../components/Loading/useLoading";

interface IAccountDeletionResponse {
  status : boolean
}

const useAccountDeletion = () => {
  const {showLoading, hideLoading} = useLoading();

  const deleteAccount = useCallback(async () => {
      try{
          showLoading({
              message : "Deleting your account..."
          });

          await callApi<IAccountDeletionResponse>(CallMethod.post, "user/delete");
      }finally {
          hideLoading();
      }
  }, []);


  return {
    deleteAccount
  }
}


export default useAccountDeletion;
