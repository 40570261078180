import store from "../store";
import Mixpanel from 'mixpanel-browser';

Mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

const trackEvent = (eventName: string, args: any = {}) => {
  try {
    const properties = {
      ...args,
      ...store.getState().appContext
    }

    const user = store.getState().user;

    console.log("Tracking Event", eventName, properties);

    const data = {
      distinct_id: user.userId ?? "1",
      ...properties
    }

    Mixpanel.track(eventName, data);
  } catch (e) {
    console.log("Track Error:", e);
  }
};

export {
  trackEvent,
};

