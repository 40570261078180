import React from 'react';
import {MessageButtonResponse, MessageResponse, EventTypes} from "./models";
import MessageButton from "./MessageButton";
import styles from "./MessageButtonsView.module.css";
import {Channel} from "stream-chat";

export interface MessageButtonsViewProps {
  message: MessageResponse;
  channel: any;
}

async function handleButtonClick(buttons : MessageButtonResponse[], buttonId : string, channel: Channel){
  //@ts-ignore
  await channel.sendEvent({
    //@ts-ignore
    type: EventTypes.buttonClicked,
    payload : {
      selectedButtonId : buttonId,
      buttons: buttons
    }
  });
}

const MessageButtonsView: React.FC<MessageButtonsViewProps> = (props) => {
  const buttons = props.message.buttons ?? [];
  if(buttons.length === 0){
    return null;
  }

  return (
    <div className={styles.buttonsContainer}>
      {
        buttons.map((button : MessageButtonResponse) =>{
          return (
            <MessageButton label={button.label} onclick={()=>{handleButtonClick(buttons, button.id, props.channel).then()}} key={button.id} />
          )
        })
      }
    </div>
  )
}


export default MessageButtonsView;
