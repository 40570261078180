import {createContext} from "react";

export interface LoadingOptions {
  message?: string;
}

export interface LoadingContextValue {
  showLoading: (options : LoadingOptions) => void;
  hideLoading: () => void;
}

export const LoadingContext = createContext<LoadingContextValue | undefined>(undefined);
