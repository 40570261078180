import {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import { AppVersion } from '@ionic-native/app-version/';
import {Device, DeviceId} from '@capacitor/device';
import {AppContext, AppPlatform} from "../AppContext";
import {setAppContext} from "../../store/actions";
import {isPlatform} from "@ionic/react";
import {getTimezone} from "../timezone-fetcher";
import {DeviceInfo} from "@capacitor/device/dist/esm/definitions";

const useAppContext = () => {
  const [appContext, setAppContextState] = useState<AppContext>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const getAppContext = async (): Promise<AppContext> => {
      try{
        const [versionNumber, versionCode, deviceInfo, deviceId] = await Promise.all([
          AppVersion.getVersionNumber(),
          AppVersion.getVersionCode(),
          Device.getInfo(),
          Device.getId()
        ])

        const device = deviceInfo as DeviceInfo;

        return {
          appVersionNumber: versionNumber.toString(),
          appVersionCode: parseInt(versionCode.toString()),
          deviceModel: device.model,
          osVersion: device.osVersion,
          platform: mapPlatform(device.platform),
          deviceId: (deviceId as DeviceId).identifier,
          timezone: getTimezone()
        }
      }catch (error){
        if(!isPlatform('capacitor') && process.env.REACT_APP_APP_CONTEXT){
          return JSON.parse(process.env.REACT_APP_APP_CONTEXT)
        }
      }
    }

    getAppContext().then((context) => {
      dispatch(setAppContext(context));
      setAppContextState(context);
    })
  }, []);

  return appContext;
};

function mapPlatform(platform : string) : AppPlatform{
  switch (platform){
    case "ios":
      return AppPlatform.iOS;
    case "android":
      return AppPlatform.android;
    case "web":
      return AppPlatform.web;
  }
}
export default useAppContext;
