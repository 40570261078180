import styles from "./MessageButton.module.css";
import React from "react";

export interface MessageButtonProps {
  label: string;
  onclick: any
}

const MessageButton: React.FC<MessageButtonProps> = (props) => {
  return (
      <button className={styles.button} onClick={()=>{props.onclick()}}>
        <div className={styles.label}>{props.label}</div>
      </button>
  );
}

export default MessageButton;
