import {AppPageRoutes} from "../routing/AppRoutes";
import {AppPage} from "../routing/models";

export class LoginPagerRoutes implements AppPageRoutes {
  page = AppPage.login;
  routes = [{
    path: "/login/:token",
   }
  ]
  deepLinkPath: string = "/login/:token"
}
