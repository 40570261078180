import {useCallback} from "react";
import {useHistory} from "react-router-dom";
import {AppPage} from "../routing/models";
import {locateDeepLinkAppRoute} from "../routing/app-deep-link-route-locator";
import {authenticateUser, logoutUser} from "../../common/user_authenticator";
import {AppRoutes} from "../routing/AppRoutes";
import useActivation from "./useActivation";

interface IDeeplinkNavigate {
  navigate: (url: string) => Promise<void>;
}

interface INavigate {
  navigateToPage: (page: AppPage) => Promise<void>;
}

export function useDeeplinkNavigate() : IDeeplinkNavigate {
  const history = useHistory();
  const { triggerActivation } = useActivation();

  const navigate = useCallback(async (url: string) : Promise<void> => {
    const route = locateDeepLinkAppRoute(url);
    if(!route) return;

    if(route.page === AppPage.login && route.params.token){
      await authenticateUser(route.params.token);
      return;
    }

    if(route.page === AppPage.logout){
      await logoutUser();
      return;
    }

    if (route.page === AppPage.activation && route.query.t) {
      await triggerActivation(route.query.t);
      return;
    }

    history.push(route.path);
  }, [history]);

  return {
    navigate,
  };
}

export function useNavigate() : INavigate {
  const history = useHistory();

  const navigateToPage = useCallback((pageType : AppPage) : Promise<void> => {
    const pageRoute = AppRoutes.find(route => pageType === route.page);
    if(!pageRoute) return;

    history.push(pageRoute.routes[0].path as string);
  }, [history]);

  return {
    navigateToPage,
  }
}
