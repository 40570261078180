import {trackEvent} from "../../../../common/analytics_tracker";
import {RoutineType} from "../components/RoutinesSection/models";
import {UserPreferencesSettingState} from "../models";
import {UserPreferencesActionType} from "./user_preferences_api_invoker";

export function trackRoutineTimeUpdate(type: RoutineType, oldTime: string, newTime: string) {
    trackEvent("Routine Time Updated", {
      type,
      from: oldTime,
      to: newTime,
    });
}

export function trackRoutineStateUpdate(type: RoutineType, action: UserPreferencesActionType) {
    trackEvent("Routine state Updated", {
      type,
      action
    });
}

export function trackUserPreferenceSettingUpdate(name: string, action: UserPreferencesSettingState) {
    trackEvent("User Preferences Setting Updated", {
      setting: name,
      action
    });
}
