import React from 'react';
import styles from "./SplashScreen.module.css"

import logo from "./assets/logo.svg";

const SplashScreen = () => {
  return (
    <div className={styles.splashContainer}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="logo"/>
          <span className={styles.splashText}>There’s so much more to life</span>
        </div>
    </div>
  )
}

export default SplashScreen;
