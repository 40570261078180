import JournalList from "../../journal/JournalList";
import React from "react";
import AddJournalEntryButton from "../../journal/AddJournalEntryButton";
import {JournalsCardResponse} from "./JournalCardResponse";

type Props = {
  card : JournalsCardResponse,
}

const JournalsCard = ({card} : Props)=>{

  return (
    <>
    <AddJournalEntryButton value={card.journal} user={card.user}/>
    <JournalList value={card.journal} user={card.user} showAddEntryButton={false}/>
    </>
  )
}

export default JournalsCard
