import React, {useState} from 'react';
import styles from './index.module.css';
import useWindowDimensions from '../../../../../../../hooks/useWindowDimensions';
import {RoutineType} from "../../models";
import {RoutineTimeEdit} from "../RoutineTimeEdit";
import {RoutineState} from "../../../../../../../store/reducers";
import useTranslation from "../../../../../../../locale/hooks/use-translation";

type TimeSetterProps = {
  type: RoutineType,
  routine: RoutineState
}

const TimeSetter = ({type, routine} : TimeSetterProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const windowDimension = useWindowDimensions();

  const onEdit = (e: any) => {
    e.preventDefault();
    setIsEditing(true);
  }

  const onEditCompleted = () => {
    setIsEditing(false);
  }

  const render = (isEditing : boolean) => {
      if(isEditing)
        return (<RoutineTimeEdit type={type} routine={routine} onEditCompleted={onEditCompleted}/>)

      return (<RoutineTime onEdit={onEdit} time={routine.time}/>)
  }

  return (
    <div className={`${styles.root} ${(windowDimension.width < 520 && isEditing) ? styles.flexColumn : ''}`}>
      {render(isEditing)}
    </div>
  )
}

interface RoutineTimeViewProps {
  onEdit(e: any): void,
  time: string,
}

const RoutineTime = ({onEdit, time } : RoutineTimeViewProps)=>{
  const {t} = useTranslation();

  return(
    <>
      <div className={styles.timeDiv}>{time}</div>
      <div className={styles.timeBtn} onClick={onEdit}>{t('button.edit')}</div>
    </>
  )
}

export default TimeSetter;
