import React, {useEffect, useState} from "react";
import useQuery from "../../hooks/useQuery";
import useActivationApi from "./hooks/useActivationApi";
import activateIcon from "./assets/activate-icon.svg";
import appstoreBadge from "./assets/appstore-badge.svg";
import {trackEvent} from "../../../common/analytics_tracker";
import styles from "./ActivationPage.module.css";

type ActivationParams = {
  t: string;
};

export default function ActivationPage() {
  const query = useQuery<ActivationParams>();
  const [link, setLink] = useState<string>();
  const [error, setError] = useState<string>();
  const { activationLink } = useActivationApi();

  useEffect(() => {
    if (!query?.t) {
      console.log('no token');
      return;
    }

    activationLink(query.t)
      .then((link) => {
        if (!link) {
          setError('Invalid activation link');
          return;
        }
        setLink(link)
      });
  }, [query?.t]);

  return (
    <>
      {!!error &&
        <div className={styles.container}>
          <img src={activateIcon} alt={"Activate Icon"} className={"image"}/>
          <div className={styles.title}>Activation</div>
          <div className={styles.info}>Sorry, but the activation link is not valid.</div>
          <div className={styles.info}>Please contact your self care provider</div>
          <div className={styles.issues}>
            <div>Experiencing issues activating your app? <br/> Contact us at <a
              href={"mailto:support@kai.ai"}>support@kai.ai</a></div>
          </div>
        </div>
      }
      {!error && !!link &&
        <div className={styles.container}>
          <img src={activateIcon} alt={"Activate Icon"} className={"image"}/>
          <div className={styles.title}>Activation</div>
          <div className={styles.info}>First, download the Kai app on your mobile device</div>
          <div className={styles.storeButtonsContainer}>
          <a href='https://apps.apple.com/us/app/kai-your-wellness-companion/id1570444738' onClick={() => {
            trackEvent("Download Mobile App Clicked", {platform: 'iOS'});
          }}>
            <img className={styles.appStoreButton}
                 alt='Get Kai on the App Store'
                 src={appstoreBadge}/>
          </a>
          <a href='https://play.google.com/store/apps/details?id=ai.kai.app' onClick={() => {
            trackEvent("Download Mobile App Clicked", {platform: 'Android'});
          }}>
            <img className={styles.playStoreButton}
                 alt='Get Kai on Google Play'
                 src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
          </a>
          </div>
          <div className={styles.info}>Then, on your mobile device, click on the button to activate your personalized
            experience with Kai
          </div>
          <a className={styles.activateButton} href={link} target={"_blank"} onClick={() => {
            trackEvent("Activate Copilot Clicked");
          }}>
            Activate
          </a>
          <div className={styles.issues}>
            <div>Experiencing issues activating your app? <br/> Contact us at <a
              href={"mailto:support@kai.ai"}>support@kai.ai</a> </div>
          </div>
        </div>
      }
    </>
  )
}
