import moment, {unitOfTime} from "moment";
import {
  FilteredEntry,
  FilterGroupType,
  FilterResultsGroupByType,
  IJournalFilter
} from "../JournalFilter/JournalFiltersList";
import {JournalEntry, JournalItem, JournalTopic} from "../../../common/models";

export function filterItems(items: JournalItem[], activeFilter : IJournalFilter): FilteredEntry[] {
    if(activeFilter.filterGroupType === FilterGroupType.all){
      return items.map(item =>{
        return {
          journals_topics : item.journals_topics,
          date : item.date.toString()
        }
      });
    }

    const dailyItems = filterDailyItems(items, activeFilter);
    return groupItems(dailyItems, activeFilter);
}

function groupItems(filteredItems : JournalItem[], activeFilter : IJournalFilter) : FilteredEntry[] {
    const groupedItems = filteredItems.reduce((groupedItems: {[key: string] : JournalEntry[]}, currentItem : JournalItem) =>{
        const date = moment(currentItem.date);
        const groupKey = activeFilter.groupBy === FilterResultsGroupByType.week ? date.week() : date.month();
        const groupJournalEntries : JournalEntry[] = groupedItems[groupKey] || [];

        const journalEntries = currentItem.journals_topics.flatMap(item => item.journal_entries)

        groupJournalEntries.push(...journalEntries);
        groupedItems[groupKey] = groupJournalEntries;
        return groupedItems;
    }, {})

    const groupedItemsByType = Object.keys(groupedItems).map(key => {
        const groupedEntries = groupedItems[key];
        const date = activeFilter.groupBy === FilterResultsGroupByType.week
            ? moment().day("Monday").week(Number(key))
            : moment().day("Monday").month(Number(key));

        const entry : FilteredEntry = {
            type : activeFilter.groupBy,
            startTime : date.startOf(activeFilter.groupBy as unitOfTime.StartOf).toDate(),
            from : date.startOf(activeFilter.groupBy as unitOfTime.StartOf).toISOString(),
            to : date.endOf(activeFilter.groupBy as unitOfTime.StartOf).toISOString(),
            journals_topics : [{
                topic :groupedEntries[0].topic,
                journal_entries : groupedEntries
            }]
        }

        return entry;
    })

    groupedItemsByType.sort((a : any, b:any) =>{
        return b.startTime - a.startTime;
    })

    return groupedItemsByType;
}

function filterDailyItems(items : JournalItem[], activeFilter : IJournalFilter) : JournalItem[]{
    return items.map((journalItem: JournalItem) => {
        const relevantTopics =  journalItem.journals_topics.filter((journalTopic: JournalTopic) => {
            return journalTopic.filterGroups.includes(activeFilter.filterGroupType)
        });

        if(!relevantTopics.length) return null;

        return {
            date : journalItem.date,
            journals_topics : relevantTopics
        }

    }).filter((dailyEntry : any) => dailyEntry != null);
}
