import {useEffect} from "react";
import {callApi, CallMethod} from "../../common/api";
import {getAppContext} from "../../common/app-context-provider";
import {useDispatch, useSelector} from "react-redux";
import {selectAppConfig} from "../../store/selectors";
import {setAppConfig} from "../../store/actions";

export interface IForceUpdatePayload {
  shouldUpdate: boolean;
  title?: string;
  message?: string;
  buttonLabel?: string;
  appStoreUrl?: string;
}

export interface PaywallConfig {
  features : string[]
}

export interface AppConfigResponse {
  forceUpdate : IForceUpdatePayload
  paywall : PaywallConfig
}

const useAppConfig = () => {
  const appConfig = useSelector(selectAppConfig);
  const dispatch = useDispatch();
  const appContext = getAppContext();

  useEffect(() => {
      if(appConfig || !appContext) return;

      const baseUrl = process.env.REACT_APP_CONFIG_API_URL;
      callApi<AppConfigResponse>(CallMethod.get, "config", {
          baseUrl,
      }).then((response) => {
        dispatch(setAppConfig(response));
    })
  }, [appConfig, appContext]);

  return appConfig;
}


export default useAppConfig;
