import React from 'react';
import {ReactNode} from "react";
import {useLocale} from "../";
import styles from './styling-provider.module.scss';

interface IStylingProviderProps {
  children: ReactNode;
}

export default function StylingProvider({ children }: IStylingProviderProps) {
  const {locale} = useLocale()

  return <div className={styles[`locale_${locale?.code || 'default'}`]}>{children}</div>;
}
