import {callApi, CallMethod} from "../../../../common/api";
import {UserPreferencesCardResponse} from "../UserPreferencesCardResponse";

export enum UpdateStatusType{
  success = "Success",
  failure = "Failure"
}

export type UpdatePreferencesResponse =  UserPreferencesCardResponse & {
  status : UpdateStatusType
  updatedTime : string
}

export enum UserPreferencesActionType {
  enable = "enable",
  disable =  "disable"
}

export async function invokeUserPreferencesAction(actionPath : string, action? : string, payload? : any) : Promise<UpdatePreferencesResponse>{
  let path = `card/user_preferences/${actionPath}`;
  if(action){
    path = path + `/${action}`;
  }

  return callApi<UpdatePreferencesResponse>(CallMethod.post, path, {
    data: payload
  });
}
