import React from "react";
import {ReactNode, useEffect} from "react";
import useTranslation from "../hooks/use-translation";

interface ITranslationProviderProps {
    children: ReactNode;
}

export default function TranslationProvider({ children }: ITranslationProviderProps) {
    const { setDictionary } = useTranslation();

    useEffect(() => {
      fetch('/locales/translations.json')
            .then((response) => response.json())
            .then((translations) => {
                setDictionary(translations);
            })
            .catch((error) => {
                console.error('Error loading translations', error);
            });
    }, []);

    return (<>{children}</>)
}
