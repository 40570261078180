import React, {useEffect, useState} from "react";
import {IonTabs, IonTabBar, IonTabButton, IonLabel, IonRouterOutlet, IonIcon} from '@ionic/react';
import {Route, Redirect, useHistory} from "react-router-dom";
import AnalyticsTracker from "../../components/AnalyticsTracker";
import {AppRoutes} from "../routing/AppRoutes";

import journalTabIcon from "./assets/journals.svg";
import profileTabIcon from "./assets/me.svg";
import practiceTabIcon from "./assets/practice.svg";
import todayTabIcon from "./assets/today.svg";
import chatTabIcon from "./assets/chat.svg";
import useAppLoadedEventPublisher from "../hooks/useAppLoadedEvent";
import {useSelector} from "react-redux";
import {selectShowPaywall} from "../../store/selectors";
import PaywallPage from "./Subscriptions/PaywallPage";
import {locatePathAppRoute} from "../routing/app-deep-link-route-locator";

const NativeMainPage = ()=> {
  const renderRoutes = () => {
    return AppRoutes.flatMap((pageRoutes) => {
      if(pageRoutes.component === null) return null;

      return pageRoutes.routes.map((route, index) => {
        return <Route path={route.path} exact={route.exact} component={pageRoutes.component} key={index}/>
      })
    }).filter(route => route);
  }

  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState('today');
  const [refreshKey, setRefreshKey] = useState(0);

  const forceRerender = () => setRefreshKey(prevKey => prevKey + 1);

  useAppLoadedEventPublisher();
  const showPaywall = useSelector(selectShowPaywall);

  useEffect(() => {
    const unsubscribe = history.listen((location) => {
      const route = locatePathAppRoute(location.pathname);
      if(route){
        forceRerender();
        console.log(`Navigating: ${selectedTab} -> ${route.page}`);
        setSelectedTab(route.page);
      }
    });

    return () => {
      unsubscribe();
    }
  }, [showPaywall, selectedTab, history]);

  const showPaywallIfNeed = () => {
    if(!showPaywall) return null;

    return (<PaywallPage/>)
  }

  return (
    <>
      <AnalyticsTracker event={"App Open"}/>
      <AnalyticsTracker event={"App Main Page Open"}/>
        <IonTabs key={refreshKey}>
          <IonRouterOutlet>
            {renderRoutes()}
            <Route exact path="/" render={() => <Redirect to="/today"/> }/>
          </IonRouterOutlet>

          <IonTabBar slot="bottom" selectedTab={selectedTab} >
            <IonTabButton tab="today" href="/today">
              <IonIcon icon={todayTabIcon}/>
              <IonLabel>Today</IonLabel>
            </IonTabButton>
            <IonTabButton tab="practice" href="/practice">
              <IonIcon icon={practiceTabIcon}/>
              <IonLabel>Practice</IonLabel>
            </IonTabButton>
            <IonTabButton tab="chat" href="/chat">
              <IonIcon icon={chatTabIcon}/>
              <IonLabel>Kai</IonLabel>
            </IonTabButton>
            <IonTabButton tab="journals" href="/journals">
              <IonIcon icon={journalTabIcon}/>
              <IonLabel>Journals</IonLabel>
            </IonTabButton>
            <IonTabButton tab="me" href="/me">
              <IonIcon icon={profileTabIcon}/>
              <IonLabel>Me</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>

        {showPaywallIfNeed()}
    </>
  )
}

export default NativeMainPage
