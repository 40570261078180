import gratefulness from "./assets/gratefulness.png";
import daily_affirmation from "./assets/daily_affirmation.png";
import good_things from "./assets/good_things.png";
import one_thing from "./assets/one_thing.png";
import open_journal from "./assets/open_journal.png";
import daily from "./assets/daily.png";
import weekend from "./assets/weekend.png";
import daily_growth from "./assets/daily_growth.png";

type IconsMap = {
  [key: string]: string;
};

const JournalIcons: IconsMap = {
  "1": gratefulness,
  "2": one_thing,
  "3": good_things,
  "4": daily_affirmation,
  "5": daily_growth,
  "6": weekend,
  "7": open_journal,
  daily: daily,
};

export default JournalIcons;
