import {useEffect, useState} from "react";
import {getAppContext} from "../../common/app-context-provider";
import {getPushNotificationToken} from "../services/push-notifications";
import {publishEvent} from "../components/AppEventsPublisher/app-event-publisher";
import {createAppReloadedEvent} from "../components/AppEventsPublisher/app-event-factory";

const useAppLoadedEventPublisher = () => {
  const [published, setPublished] = useState<boolean>(false);
  const appContext = getAppContext();

  useEffect(() => {
    if (published || !appContext) return;

    const publishAppLoadedEvent = async () => {
      const token = await getPushNotificationToken();
      const event = createAppReloadedEvent(token?.toString());
      await publishEvent(event);
      setPublished(true);
    }

    publishAppLoadedEvent().then();
  }, [appContext]);

  return;
}


export default useAppLoadedEventPublisher;
