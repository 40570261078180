import {AppPageRoutes} from "../routing/AppRoutes";
import {AppPage} from "../routing/models";

export class LogoutPagerRoutes implements AppPageRoutes {
  page = AppPage.logout;
  routes = [{
    path: "/logout/",
   }
  ]
  deepLinkPath: string = "/logout/"
}
