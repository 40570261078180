import React, {useEffect} from "react";
import {InfiniteScrollCustomEvent, IonInfiniteScroll, IonInfiniteScrollContent} from "@ionic/react";
import {isNativePlatform, usePlatform} from "../../common/hooks/usePlatform";
import {InfiniteScrollFetchResult} from "./InfiniteScrollFetchResult";

type Props = {
  threshold: number;
  loadMoreCallback : () => Promise<InfiniteScrollFetchResult>
};

const InfiniteScroll = ({ threshold, loadMoreCallback }: Props) => {
  const platform = usePlatform();

  const renderInfiniteScroll = () => {
    if(isNativePlatform(platform)){
      return <NativeInfiniteScroll threshold={threshold} loadMoreCallback={loadMoreCallback}/>
    }

    return <MobileWebInfiniteScroll threshold={threshold} loadMoreCallback={loadMoreCallback}/>;
  }

  return renderInfiniteScroll();
};

const MobileWebInfiniteScroll = ({ threshold, loadMoreCallback }: Props) : any => {
  const [hasMore, setHasMore] = React.useState(true);
  const isFetchingRef = React.useRef(false);

  const handleWebScroll = async (event: any) => {
    const reachedEndOfPage = window.innerHeight + window.scrollY >= event.target.body.offsetHeight - threshold;
    if (reachedEndOfPage && !isFetchingRef.current && hasMore) {
      isFetchingRef.current = true;
      const result = await loadMoreCallback();
      setHasMore(result.hasMore);
    }
  };

  useEffect(() => {
    const registerToScrollEvents = () => {
      window.addEventListener("scroll", handleWebScroll);
      return () => {
        window.removeEventListener("scroll", handleWebScroll);
      };
    }

    registerToScrollEvents();
  }, [hasMore]);

  return null;
};

const NativeInfiniteScroll = ({ threshold, loadMoreCallback }: Props) => {
  const handleLoadMore = async (event : InfiniteScrollCustomEvent) => {
    await loadMoreCallback();
    await event.target.complete();
  }

  return (
    <IonInfiniteScroll threshold={`${threshold}px`} onIonInfinite={handleLoadMore}>
      <IonInfiniteScrollContent loadingText="Loading more..." />
    </IonInfiniteScroll>
  )
};

export {
  InfiniteScroll
}
