import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as activationApi from "../services/activation-api";
import {
  resetPendingActivationToken, setPendingActivationToken,
  setShowActivation,
  setShowPaywall
} from "../../store/actions";
import {useLoading} from "../components/Loading/useLoading";
import {useNavigate} from "./useNavigate";
import {AppPage} from "../routing/models";
import {selectPendingActivationToken} from "../../store/selectors";
import {Dialog} from "@capacitor/dialog";

interface IActivationApi {
  activateUser: () => Promise<void>;
  activateCopilot: () => Promise<void>;
  triggerActivation: (activationToken: string) => Promise<void>;
}

export default function useActivation() : IActivationApi {
  const { showLoading, hideLoading } = useLoading();
  const { navigateToPage } = useNavigate();
  const dispatch = useDispatch();
  const activationToken = useSelector(selectPendingActivationToken);

  const activateUser = useCallback(async () : Promise<void> => {
    try {
      showLoading({
        message: 'Activating Your Kai...',
      });

      const activated = await activationApi.activateUser();
      if (activated === true) {
        dispatch(setShowActivation(false));
        dispatch(setShowPaywall(true));
        await navigateToPage(AppPage.chat);
      }
    } finally {
      hideLoading();
    }

  }, [dispatch, navigateToPage, showLoading, hideLoading]);

  const activateCopilot = useCallback(async () : Promise<void> => {
    if (!activationToken)
    {
      await Dialog.alert({
        title: 'Activation failed',
        message: 'Token is missing',
      });
      return;
    }

    try {
      showLoading({
        message: 'Activating Your Kai...',
      });
      const activated = await activationApi.activateCopilot(activationToken);
      if (activated === true) {
        dispatch(setShowActivation(false));
        dispatch(resetPendingActivationToken());
        await navigateToPage(AppPage.chat);
      }
    } finally {
      hideLoading();
      dispatch(resetPendingActivationToken());
    }
  }, [activationToken, dispatch, navigateToPage, showLoading, hideLoading]);

  const triggerActivation = useCallback(async (activationToken: string) : Promise<void> => {
    dispatch(setPendingActivationToken(activationToken));
  },[dispatch]);

  return {
    activateUser,
    activateCopilot,
    triggerActivation,
  }
}
