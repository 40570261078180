import React, {useEffect} from 'react';
import RoutineSettings from "./RoutineSettings";
import {RoutineType} from "./models";
import styles from "./index.module.css";
import {useSelector} from "react-redux";
import {selectUserPreferences} from "../../../../../store/selectors";

export default function RoutinesSection() {
  const preferences = useSelector(selectUserPreferences);

  useEffect(() => {
  }, [preferences]);

  const render = () => {
    if(!preferences) return null;

    return (
      <div className={styles.container}>
        <RoutineSettings
          type={RoutineType.morning}
          routine={preferences?.morningRoutine}
          toggle={false}
        />
        <div className={styles.divider}/>
        <RoutineSettings
          type={RoutineType.evening}
          routine={preferences?.eveningRoutine}
          toggle={true}
        />
      </div>
    )
  }

  return render();
}


