import pinkHeart from "./assets/pink_heart.svg"
import blueHeart from "./assets/blue_heart.svg"
import greenTrophy from "./assets/green_trophy.svg"
import orangeHeart from "./assets/orange_heart.svg"

import {HappinessScoreCardBadgeTypes} from "./HappinessScoreCardThemes";

const HappinessScoreCardBadges: { [key:string] : any} = {
  [HappinessScoreCardBadgeTypes.pinkHeart]: pinkHeart,
  [HappinessScoreCardBadgeTypes.blueHeart]: blueHeart,
  [HappinessScoreCardBadgeTypes.orangeHeart]: orangeHeart,
  [HappinessScoreCardBadgeTypes.greenTrophy]: greenTrophy

}

export default HappinessScoreCardBadges;
