import CardsBasedTabPage from "../CardsBasedTabPage";
import React from "react";
import AnalyticsTracker from "../../../components/AnalyticsTracker";
import {IonContent} from "@ionic/react";


const ProfileTabPage = () =>{

  return (
    <AnalyticsTracker event={"App Profile Tab Loaded"}/> &&
    <IonContent>
      <CardsBasedTabPage tabName={"profile"} />
    </IonContent>
  )
}

export default ProfileTabPage;
