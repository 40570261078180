import React from "react";
import Homepage from "./Pages/Homepage/Homepage";
import useWebAuthentication, {WebUserAuthState} from "./hooks/useWebAuthentication";
import SessionExpired from "../components/SessionExpired/SessionExpired";
import ErrorPage from "../components/ErrorPage/ErrorPage";
import "./MobileWebApp.css"
import ExercisePlayer from "../components/ExercisePlayer";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ConnectApp from "./Pages/ConnectApp/ConnectApp";
import ChatPage from "./Pages/ChatPage/ChatPage";
import {ActivationPage} from "./Pages/Activation";
import LocaleProvider from "../locale/components/locale-provider";
import Loading from "../components/loading";

const renderScreenAccordingToState = (authenticationState: WebUserAuthState) => {
  switch (authenticationState){
    case WebUserAuthState.notAuthenticated: return (<Loading/>);
    case WebUserAuthState.authenticated: return renderAllPages();
    case WebUserAuthState.sessionExpired: return (<SessionExpired/>);
  }

  return (<ErrorPage/>)
}

const renderAllPages = () =>{
  return (
    <div className="mobileWebContainer">
      <Switch>
        <Route  path="/home" exact={true}><Homepage/></Route>
        <Route  path="/error"><ErrorPage/></Route>
        <Route  path="/expired"><SessionExpired/></Route>
        <Route  path="/practice/:exerciseName" component={ExercisePlayer} />
        <Route  path="/login"><ConnectApp/></Route>
        <Route  path="/chat"><ChatPage/></Route>
        <Route  path="/"><Homepage/></Route>
      </Switch>
    </div>
  )
}

const AuthenticatedPages = () => {
  const authenticationState = useWebAuthentication();

  return renderScreenAccordingToState(authenticationState);
}

const MobileWebApp = () => {
  return (
    <Router>
      <LocaleProvider>
        <Switch>
          <Route path={"/activate"}><ActivationPage/></Route>
          <Route>
            <AuthenticatedPages/>
          </Route>
        </Switch>
      </LocaleProvider>
    </Router>
  );
}

export default MobileWebApp;
