import React, {useCallback } from 'react';
import { useIonLoading } from '@ionic/react';
import {LoadingContext, LoadingOptions} from "./LoadingContext";
import styles from "./loading.module.css"

interface LoadingProviderProps {
  children: React.ReactNode;
}

export const LoadingProvider = ({ children } :  LoadingProviderProps) => {
  const [show, dismiss] = useIonLoading();

  const showLoading = useCallback(async ({message}: LoadingOptions) => {
    await show({
      message,
      cssClass: styles.loading
    });
  }, [show]);

  const hideLoading = useCallback( async () => {
    await dismiss();
  }, [dismiss]);

  return (
    <LoadingContext.Provider value={{ showLoading, hideLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};
