import {IJournalFilter, JournalFiltersList} from "../../components/journal/JournalFilter/JournalFiltersList";
import {
  LOGOUT_USER,
  SET_APP_CONFIG,
  SET_APP_CONTEXT,
  SET_AUTO_START_EXERCISE,
  SET_CARD_DATA,
  SET_DEEP_LINK_ROUTE,
  SET_SHOW_PAYWALL,
  SET_TAB_DATA,
  SET_USER,
  SET_ACTIVATION,
  SetCardDataActionPayload,
  SetTabDataActionPayload, SET_PENDING_ACTIVATION_TOKEN
} from "../actionTypes";
import {Journal} from "../../common/models";
import {DeepLinkRoute} from "../../NativeApp/routing/app-deep-link-route-locator";
import {Exercise} from "../../NativeApp/Pages/Practice/model";
import {AppContext} from "../../common/AppContext";
import {RoutineActivityState} from "../../components/cards/UserPreferencesCard/UserPreferencesCardResponse";
import {AppConfigResponse} from "../../NativeApp/hooks/useAppConfig";

export type ToggleSettingType = {
  name: string,
  text: string,
  state: boolean
};

export interface RoutineState{
  time: string;
  fieldName: string;
  state?: RoutineActivityState;
}

export interface PlanState {
  name: string;
  details: string;
  endDate?: Date;
  offerUpgrade: boolean;
}

export type UserPreferencesType = {[key: string]: any} & {
  morningRoutine: RoutineState,
  eveningRoutine: RoutineState,
  settings: Array<ToggleSettingType>
  plan: PlanState,
  initialized: boolean
};

export interface IChatContext{
    token : string,
    channel : string,
    appKey : string,
}

export type UserStateInfo = {
  userId? : string,
  sourceId? : string,
  token? : string,
  chat? : IChatContext,
  chatToken? : string,
  renewSessionLink? : string
}

type TabsState = {[key: string]: any[]};
type CardsDataState = {[key: string]: any[]};

export type GlobalAppState = {
  activeJournalFilter? : IJournalFilter,
  userPreferences? : UserPreferencesType,
  authenticated? : boolean,
  user? : UserStateInfo,
  tabsData : TabsState,
  cardsData : CardsDataState,
  journal : Journal,
  deepLinkRoute : DeepLinkRoute,
  autoStartExercise : Exercise | null
  appContext : AppContext
}

const initialState = {
  activeJournalFilter: JournalFiltersList[0],
  userPreferences: {} as UserPreferencesType,
  authenticated : false,
  user : {} as UserStateInfo,
  tabsData : {} as TabsState,
  cardsData : {} as CardsDataState,
  deepLinkRoute : {} as DeepLinkRoute,
  autoStartExercise : "",
  appContext : null as AppContext,
  appConfig : null as AppConfigResponse,
  showPaywall : false,
  activation: false,
  pendingActivationToken: '',
}

function rootReducer(state   = initialState, action: any) {
  if(action.type === LOGOUT_USER){
    return initialState;
  }

  if (action.type === "SET_FILTER") {
    return {
      ...state,
      activeJournalFilter: action.value,
    };
  }
  if (action.type === "SET_USER_PREFERENCES") {
    return {
      ...state,
      userPreferences: {
        ...state.userPreferences,
        ...action.payload as Partial<UserPreferencesType>
      }
    }
  }

  if (action.type === SET_USER) {
    return {
      ...state,
      authenticated: true,
      user: action.payload
    };
  }

  if (action.type === SET_TAB_DATA) {
    const payload = action.payload as SetTabDataActionPayload;
    const tabsData = state.tabsData;
    tabsData[payload.tab] = payload.data

    return {
      ...state,
      tabsData : tabsData
    };
  }

  if (action.type === SET_CARD_DATA) {
    const payload = action.payload as SetCardDataActionPayload;
    const cardsData = state.cardsData;
    cardsData[payload.card] = payload.data

    return {
      ...state,
      cardsData : cardsData
    };
  }

  if (action.type === SET_DEEP_LINK_ROUTE) {
    return {
      ...state,
      deepLinkRoute: action.payload.route
    };
  }

  if (action.type === SET_AUTO_START_EXERCISE) {
    return {
      ...state,
      autoStartExercise: action.payload.autoStartExercise
    };
  }

  if (action.type === SET_APP_CONTEXT) {
    return {
      ...state,
      appContext: action.payload.context
    };
  }

  if (action.type === SET_APP_CONFIG) {
    return {
      ...state,
      appConfig: action.payload.config
    };
  }

  if(action.type === SET_SHOW_PAYWALL){
    return {
      ...state,
      showPaywall : action.payload.showPaywall
    }
  }

  if (action.type === SET_ACTIVATION) {
    return {
      ...state,
      activation: action.payload.activation,
    }
  }

  if (action.type === SET_PENDING_ACTIVATION_TOKEN) {
    return {
      ...state,
      pendingActivationToken: action.payload.pendingActivationToken,
    }
  }

  return state;
}

export default rootReducer;

export type rootReducer = ReturnType<typeof rootReducer>;
