import {trackEvent} from "./analytics_tracker";

const journal = {
  entryClicked: () => {
    trackEvent("New Journal Entry Clicked");
  },
  entryCancelled: () => {
    trackEvent("New Journal Entry Canceled");
  },
  entryCreated: (text: string) => {
    trackEvent("Open Journal Entry Created", { text_length: text.length });
  },
};

export default {
  journal,
};
