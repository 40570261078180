import {useEffect} from 'react';
import {AppPlatform} from "../AppContext";
import useAppContext from "./useAppContext";

export const usePlatform = () : AppPlatform => {
  const appContext = useAppContext();

  useEffect(() => {
  }, [appContext]);

  return appContext?.platform;
};

export const isNativePlatform = (platform : AppPlatform) : boolean => {
  return platform === AppPlatform.iOS || platform === AppPlatform.android;
}
