import React, {useEffect, useState} from "react";
import {IonApp} from '@ionic/react';
import { setupIonicReact } from '@ionic/react';
import AppUrlListener from "./AppUrlListener";
import useNativeAuthentication from "./hooks/useNativeAuthentication";
import "./NativeApp.css"
import {IonReactRouter} from "@ionic/react-router";
import AuthenticationRouter from "./AuthenticationRouter";
import useAppContext from "../common/hooks/useAppContext";
import ForceUpgrade from "./components/ForceUpgrade";
import {PushNotificationsEventsListeners} from "./components/PushNotifications";
import SplashScreen from "./Pages/SplashScreen/SplashScreen";
import {LoadingProvider} from "./components/Loading/LoadingProvider";

setupIonicReact({
  mode: 'md'
});

const NativeApp = () => {
  const [showSplashScreen, setShowSplashScreen] = useState<boolean>(true);
  const {isAuthenticated, queriedUserInfo} = useNativeAuthentication();
  const appContext = useAppContext();

  useEffect(() => {
    if(!appContext) return;

    if (queriedUserInfo) {
      setTimeout(() => {
        setShowSplashScreen(false);
      }, 2000);

    }
  }, [isAuthenticated, queriedUserInfo, appContext])

  return (
    <IonApp>
      <LoadingProvider>
        <ForceUpgrade />
        <IonReactRouter>
          <PushNotificationsEventsListeners />
          <AppUrlListener/>
          {showSplashScreen ? <SplashScreen /> : <AuthenticationRouter/>}
        </IonReactRouter>
      </LoadingProvider>
    </IonApp>
  )
}

export default NativeApp;
