import React from "react";

const EditIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    {...props}
  >
    <defs>
      <filter id="prefix__a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.243137 0 0 0 0 0.200000 0 0 0 0 0.188235 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="#000" fillRule="nonzero" filter="url(#prefix__a)">
      <path d="M12.937.099a.337.337 0 00-.476 0l-7.94 7.94a.335.335 0 00-.095.19l-.422 2.953a.338.338 0 00.376.383l3.002-.373a.335.335 0 00.197-.096l7.94-7.94a.338.338 0 00-.001-.477L12.938.1zM7.183 10.537l-2.449.304.343-2.405 5.112-5.112 2.103 2.104-5.109 5.109zM12.77 4.95l-2.103-2.103L12.699.815l2.103 2.103L12.77 4.95z" />
      <path d="M14.1 13.955a.502.502 0 01-.513.508H1.193a.51.51 0 01-.518-.508V2.193a.518.518 0 01.518-.518h6.645V1H1.193A1.194 1.194 0 000 2.193v11.762a1.184 1.184 0 001.193 1.183h12.394a1.18 1.18 0 001.188-1.183V6.85H14.1v7.105z" />
    </g>
  </svg>
);

export default EditIcon;
