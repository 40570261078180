import {useLayoutEffect} from "react";
import {CardResponse} from "../../model/CardsResponse";
import {callApi, CallMethod} from "../../common/api";
import {useDispatch, useSelector} from "react-redux";
import {GlobalAppState} from "../../store/reducers";
import {setTabData} from "../../store/actions";

type CardsBasedTabResponse = {
  payload : {
    cards : CardResponse[];
  }
}

const useTabsCardsListProvider = (tabName : string) => {
  const cardsListCache = useSelector((state: GlobalAppState) =>{
    return state.tabsData[tabName];
  });

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if(cardsListCache) return;

    callApi<CardsBasedTabResponse>(CallMethod.get, `tabs/${tabName}`).then(response => {
      dispatch(setTabData(tabName, response.payload.cards))
    })

  }, []);

  return cardsListCache;
};

export default useTabsCardsListProvider;
