import React, {
  useState,
  FormEvent,
  useRef,
  useEffect,
  ChangeEvent,
} from "react";
import styles from "./styles.module.scss";
import ConfirmationModal from "../../ConfirmationModal";

type Props = {
  name: string;
  onSubmit: (content: string) => Promise<void>;
  onClose: () => void;
};

const AddEntryForm = ({ name, onSubmit, onClose }: Props) => {
  const [value, setValue] = useState("");
  const [isSaving, setSaving] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSaving(true);
    await onSubmit(value);
    setSaving(false);
  };

  const handleClose = () => {
    if (value.length === 0) {
      onClose();
    } else {
      setConfirmModalOpen(true);
    }
  };

  const handleValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
    if (inputRef.current) {
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <>
      {confirmModalOpen && (
        <ConfirmationModal
          message="Are you sure you want to discard this entry?"
          onCancel={() => setConfirmModalOpen(false)}
          onConfirm={onClose}
        />
      )}
      <div className={styles.overlay} onClick={handleClose} />
      <form className={styles.panel} onSubmit={handleSubmit}>
        <div className={styles.panelHeader}>
          <button
            type="button"
            className={styles.closeButton}
            onClick={handleClose}
          />
          <h3>Open journal</h3>
          <button
            type="submit"
            disabled={value.length === 0 || isSaving}
            className={styles.button}
          >
            {isSaving ? "Saving..." : "Save"}
          </button>
        </div>
        <div className={styles.panelBody}>
          <p>{name}, share any thoughts you'd like here</p>
          <textarea
            value={value}
            onChange={handleValueChange}
            placeholder="Type your answer here"
            autoFocus
            ref={inputRef}
          />
          <button
            type="submit"
            disabled={value.length === 0 || isSaving}
            className={styles.button}
          >
            {isSaving ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </>
  );
};

export default AddEntryForm;
