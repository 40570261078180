import whatsapp from "./assets/whatsapp.svg"
import messages from "./assets/messages.svg"
import discord from "./assets/discord.svg"
import telegram from "./assets/telegram.svg"
import React from "react";
import {PlatformType} from "../../common/models";

type PlatformIcon = {
  platform : PlatformType,
  icon : any
}

const icons : PlatformIcon[] = [
  {
    platform : PlatformType.WhatsApp,
    icon : whatsapp,
  },
  {
    platform : PlatformType.AppleBusinessChat,
    icon : messages
  },
  {
    platform : PlatformType.Telegram,
    icon : telegram
  },
  {
    platform : PlatformType.Discord,
    icon : discord
  }
]

type Props = {
  platform : PlatformType
  imgClassName : string
}

export function PlatformIconImage({ platform, imgClassName } : Props) {
  const icon = icons.find(icon => icon.platform === platform);

  return (
      <img src={icon.icon} alt={platform} className={imgClassName}/>
    )

}
