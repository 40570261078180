export const SET_FILTER = "SET_FILTER";
export const SET_USER_PREFERENCES = "SET_USER_PREFERENCES";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SET_USER = "SET_USER"

export const SET_TAB_DATA = "SET_TAB_DATA"
export const SET_CARD_DATA = "SET_CARD_DATA"

export const SET_DEEP_LINK_ROUTE = "SET_DEEP_LINK_ROUTE"

export const SET_AUTO_START_EXERCISE = "SET_AUTO_START_EXERCISE"

export const SET_APP_CONTEXT = "SET_APP_CONTEXT"

export const SET_APP_CONFIG = "SET_APP_CONFIG"

export const SET_SHOW_PAYWALL = "SET_SHOW_PAYWALL"

export const SET_ACTIVATION = 'SET_ACTIVATION';

export const SET_PENDING_ACTIVATION_TOKEN = 'SET_PENDING_ACTIVATION_TOKEN';
export const LOGOUT_USER = "LOGOUT_USER"

export type SetTabDataActionPayload = {
  tab : string,
  data : any
}

export type SetCardDataActionPayload = {
  card : string,
  data : any
}
