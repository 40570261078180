import {useEffect, useState} from "react";
import {StreamChat} from "stream-chat";
import {getUser} from "../../common/user-provider";

export const useChatClient = () => {
  const [chatClient, setChatClient] = useState(null);

  const userDetails = getUser();

  useEffect(() => {
    const client = new StreamChat(userDetails.chat.appKey);
    // prevents application from setting stale client (user changed, for example)
    let didUserConnectInterrupt = false;

    const connectionPromise = client.connectUser({id: userDetails.userId}, userDetails.chat.token).then(() => {
      if (!didUserConnectInterrupt) setChatClient(client);
    });

    return () => {
      didUserConnectInterrupt = true;
      setChatClient(null);
      // wait for connection to finish before initiating closing sequence
      connectionPromise
        .then(() => client.disconnectUser())
        .then(() => {
          console.log('connection closed');
        });
    };
  }, [userDetails.chat]);

  return chatClient;
};
