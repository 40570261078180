import React from "react";
import useTabsCardsListProvider from "./useTabsCardsListProvider";
import styles from "./CardsBasedTabPage.module.css"
import CardsList from "../../components/cards/CardsList";
import Loading from "../../components/loading";

type Props = {
  tabName: string;
};

const CardsBasedTabPage = ({tabName} : Props) =>{
  const cards = useTabsCardsListProvider(tabName);

  return (
    <div className={styles.tabPage}>
      {cards?.length
        ? <CardsList cards={cards}/>
        : <Loading className="loadingContainer"/>
      }
    </div>
  )
}

export default CardsBasedTabPage
