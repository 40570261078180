import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {IonPage} from "@ionic/react";
import styles from './Activation.module.css';
import AnalyticsTracker from "../../../components/AnalyticsTracker";
import logo from "../../Onboarding/assets/logo.svg";
import useActivation from "../../hooks/useActivation";
import {selectPendingActivationToken} from "../../../store/selectors";

export default function ActivatingPage() {
  const { activateCopilot } = useActivation();
  const activationToken = useSelector(selectPendingActivationToken);

  useEffect(() => {
    if (activationToken) {
      // A timeout? Are you kidding me?
      // Indeed, this is a workaround for a race condition between the sign-in process, which has a loader. When sign in is completed the loader is marked to be hidden
      // so when we try to show it again, it is not shown.
      // Nothing we can do about this since the loader is part of Ionic 🤷
      setTimeout(() => {
        void activateCopilot();
      }, 500);
    }
  }, [activationToken, activateCopilot]);

  return (
    <IonPage>
      <AnalyticsTracker event={"Activating Page Loaded"}/>
      <div className={styles.container}>
        <div className={styles.content}>
          <img src={logo} alt={"logo"} className={styles.logo}/>
        </div>
      </div>
    </IonPage>
  )
}
