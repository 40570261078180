import React from 'react';
import styles from "./index.module.css";
import {useSelector} from "react-redux";
import {selectUserPreferences} from "../../../../../store/selectors";
import {AppPage} from "../../../../../NativeApp/routing/models";
import {PlanState} from "../../../../../store/reducers";
import { useNavigate } from "../../../../../NativeApp/hooks/useNavigate";

export default function PlanSection() {
  const preferences = useSelector(selectUserPreferences);

  const planDetails = `Plan: ${preferences?.plan?.name}`;

  const renderPlanDetails = () => {
    if(!preferences?.plan) return null;

    return (
      <div className={styles.container}>
        <div className={styles.details}>{planDetails}</div>
        <UpgradeButton plan={preferences.plan}/>
      </div>
    )
  }

  return renderPlanDetails();
}

interface UpgradeButtonProps {
  plan: PlanState
}

const UpgradeButton = ({plan} : UpgradeButtonProps) =>{
  const { navigateToPage } = useNavigate();

  if(!plan.offerUpgrade) return null;

  const upgradeClicked = async () => {
    await navigateToPage(AppPage.paywall)
  }

  return <div className={styles.upgrade} onClick={upgradeClicked}>UPGRADE</div>
}
