import {atom, useRecoilState} from "recoil";
import {useCallback} from "react";
import {useLocale} from "../index";
import {DEFAULT_LOCALE} from "../model/supported-locales";
import Mustache from "mustache";

interface IDictionary {
    [key: string]: {
        [key: string]: string
    }
}

const dictionaryState = atom<IDictionary>({
    key: 'dictionary-state',
    default: {},
});

interface ITranslationOptions {
    defaultText?: string;
    explicitCode?: string;
    params?: any;
}

const DEFAULT_TRANSLATION_OPTIONS: ITranslationOptions = {
    defaultText: '',
}

interface IUseTranslationResult {
    t: (key: string, options?: ITranslationOptions) => string;
    setDictionary: (dictionary: IDictionary) => void;
}

export default function useTranslation() : IUseTranslationResult {
    const [dictionary, setDictionary] = useRecoilState(dictionaryState);
    const {locale} = useLocale();

    const _t = useCallback((key: string, options?: ITranslationOptions) => {
        const { defaultText, explicitCode, params } = { ...DEFAULT_TRANSLATION_OPTIONS, ...options };

        if (!dictionary) {
            return defaultText;
        }

        const code = explicitCode || locale?.code || DEFAULT_LOCALE;

        const text = dictionary[key]?.[code];
        if (!text) {
            if (!defaultText) console.error('Translation not found for key:', key);
            return defaultText;
        }

        return Mustache.render(text, params);

    }, [dictionary, locale]);

    return {
        t: _t,
        setDictionary
    };
}
