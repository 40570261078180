import React, {useState} from 'react';
import styles from './index.module.css';
import {useDispatch, useSelector} from 'react-redux';
import {SET_USER_PREFERENCES} from '../../../../../../../store/actionTypes';
import {selectUserPreferences} from '../../../../../../../store/selectors';
import {trackRoutineTimeUpdate} from '../../../../services/preferences-events-tracker';
import {invokeUserPreferencesAction, UpdateStatusType} from "../../../../services/user_preferences_api_invoker";
import {RoutineType, RoutineTypeFields} from "../../models";
import {RoutineState} from "../../../../../../../store/reducers";
import useTranslation from "../../../../../../../locale/hooks/use-translation";

interface RoutineTimeEditProps {
  onEditCompleted(): void,
  routine : RoutineState,
  type: RoutineType
}

export const RoutineTimeEdit = ({routine, type, onEditCompleted}: RoutineTimeEditProps)=>{
  const userPreferences = useSelector(selectUserPreferences);
  const [routineTime, setRoutineTime] = useState(routine.time);
  const [isUpdating, setIsUpdating] = useState(false);
  const {t} = useTranslation();

  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();

  const min = type === RoutineType.morning ? "00:00" : "12:00";
  const max = type === RoutineType.morning ? "11:59" : "23:59";

  const onDone = async (e: any) => {
    e.preventDefault();
    if(isUpdating) return;

    if (routine.time === routineTime) {
      onEditCompleted();
      return;
    }

    if (routineTime < min || routineTime > max) {
        const timeDescription = type === RoutineType.morning ? "morning" : "end of the day";
        setErrMsg(`Invalid ${timeDescription} time!`);
    } else {
      try {
        const payload = {
          time: routineTime,
          fieldName: routine.fieldName
        }

        setIsUpdating(true);
        const response = await invokeUserPreferencesAction("update", "", payload)

        if (response.status === UpdateStatusType.success) {
          const routineStateField = RoutineTypeFields[type];

          trackRoutineTimeUpdate(type, routine.time, response.updatedTime);
          dispatch({
            type: SET_USER_PREFERENCES,
            payload: {
              [routineStateField]: {
                ...userPreferences[routineStateField],
                time: response.updatedTime
              }
            }
          });

          onEditCompleted();
        } else {
          throw new Error('Updated Failed');
        }
      } catch (e) {
        console.log('update error:', e);
      }
      finally {
        setIsUpdating(false);
      }
    }
  }

  const handleChange = (e: any) => {
    setRoutineTime(e.target.value);
    setErrMsg("");
  }

  return (
    <div className={styles.container}>
      <div className={styles.inputWrapper}>
        <input
          type={"time"}
          value={routineTime}
          min={min}
          max={max}
          onChange={handleChange}
          className={styles.timeInput}
        />
        {errMsg && <div className={styles.err}>{errMsg}</div>}
      </div>

      <div className={styles.timeBtn} onClick={onDone}>{t('card.preferences.routines.edit.done')}</div>
    </div>
  )
}
