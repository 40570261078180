import React, {useEffect} from "react";
import {
  Chat,
  Channel,
  LoadingIndicator,
  MessageInput,
  MessageList,
  Window,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';
import {useChatClient} from "./useChatClient";
import ChatMessage from "./Message/ChatMessage";
import {useChatChannel} from "./useChatChannel";

function renderChat(chatClient: any, channel: any){
  return (
    <div>
      <Chat client={chatClient}>
        <Channel channel={channel} Message={ChatMessage}>
          <Window>
            <MessageList />
            <MessageInput />
          </Window>
        </Channel>
      </Chat>
    </div>
  )
}

function renderLoading(){
  return (<LoadingIndicator />)
}

const ChatView: React.FC = () => {
  const chatClient = useChatClient();
  const channel = useChatChannel();

  useEffect(() => {
  }, [chatClient, channel]);

  return (
    channel
      ? renderChat(chatClient, channel)
      : renderLoading()
  )
}

export default ChatView
