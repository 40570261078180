import {useEffect} from "react";
import useAppConfig, {IForceUpdatePayload} from "../../hooks/useAppConfig";
import {Dialog} from "@capacitor/dialog";

const ForceUpgrade = () : any => {
  const appConfig = useAppConfig();

  useEffect(() => {
      if(!appConfig || !appConfig.forceUpdate.shouldUpdate) return;
      showDialog(appConfig.forceUpdate).then(()=>{})
  }, [appConfig]);

  return null;

}

const showDialog = async (forceUpdate : IForceUpdatePayload) => {
  await Dialog.alert({
    title: forceUpdate.title,
    message: forceUpdate.message,
    buttonTitle: forceUpdate.buttonLabel,
  });
  window.open(forceUpdate.appStoreUrl, '_system');

  await showDialog(forceUpdate);
};


export default ForceUpgrade
