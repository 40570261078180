import React from "react";
import styles from "./ErrorPage.module.css";
import AnalyticsTracker from "../AnalyticsTracker";

export default function ErrorPage() {
  return (
    <div className={styles.container}>
      <AnalyticsTracker event="Mobile Web Page Visit" params={{ page : "error" }}/>
      <div className={styles.content}>Oops... something wrong just happened.</div>
      <div className={styles.content}>Please wait for a few minutes and try to reload the app.</div>
      <div className={styles.content}>In case the issue persists, contact us at <a href={"mailto:support@kai.ai"}>support@kai.ai</a> </div>
    </div>
  )
}
