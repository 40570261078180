import {useEffect, useState} from "react";
import {Edits, Journal} from "../../../common/models";
import api from "../../../common/api";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {GlobalAppState} from "../../../store/reducers";
import {setCardData} from "../../../store/actions";
import {InfiniteScrollFetchResult, InfiniteScrollFetchStatus} from "../../InfiniteScroll/InfiniteScrollFetchResult";

const cardName = "journal"

const useJournalHandler = (initial: Journal) => {
  const dispatch = useDispatch();
  const journalState = useSelector((state: GlobalAppState) =>{
    return state.cardsData[cardName];
  }, shallowEqual)

  // @ts-ignore
  const journal = journalState as Journal || initial;

  const setJournal = (journal : Journal) =>{
    dispatch(setCardData(cardName, journal));
  }

  const [isLoading, setLoading] = useState(false);
  const [hasMoreJournalItems, setHasMoreJournalItems] = useState(true);

  const loadMoreJournalItems = async () : Promise<InfiniteScrollFetchResult> => {
    const newJournal = await api.getJournal(journal.minDate);
    setLoading(false);

    if (!newJournal || !newJournal.items){
      setHasMoreJournalItems(false);
      return {
        hasMore : false,
        status : InfiniteScrollFetchStatus.success
      }
    }

    setJournal({
      ...newJournal,
      items: [...journal.items, ...newJournal.items],
    });

    return {
      hasMore : false,
      status : InfiniteScrollFetchStatus.success
    }
  };

  const handleAddEntry = async (description: string) => {
    await api.addJournalEntry(description);
    const newData = await api.getJournal(new Date().getTime());
    const dates = newData.items.map((item) => item.date);
    setJournal({
      ...journal,
      items: [
        ...newData.items,
        ...journal.items.filter((item) => !dates.includes(item.date)),
      ],
    });
  };

  const handleDeleteEntry = async (id: string) => {
    setJournal({
      ...journal,
      items: journal.items
        .map((item) => ({
          ...item,
          journals_topics: item.journals_topics
            .map((topic) => ({
              ...topic,
              journal_entries: topic.journal_entries.filter(
                (entry) => entry.id !== id
              ),
            }))
            .filter((topic) => topic.journal_entries.length > 0),
        }))
        .filter((item) => item.journals_topics.length > 0),
    });
    await api.deleteJournalEntry(id);
  };

  const handleEditEntries = async (edits: Edits) => {
    setJournal({
      ...journal,
      items: journal.items.map((item) => ({
        ...item,
        journals_topics: item.journals_topics.map((topic) => ({
          ...topic,
          journal_entries: topic.journal_entries.map((entry) => {
            if (edits[entry.id]) {
              return {
                ...entry,
                content: edits[entry.id],
              };
            }
            return entry;
          }),
        })),
      })),
    });
    const entries = Object.keys(edits).map((key) => ({
      id: key,
      description: edits[key],
    }));
    await api.editJournalEntries(entries);
  };

  useEffect(() => {
    if(isLoading){
      void loadMoreJournalItems();
    }
  }, [isLoading]);

  return {
    journal,
    handleAddEntry,
    handleDeleteEntry,
    handleEditEntries,
    loadMoreJournalItems,
    hasMoreJournalItems,
  };
};

export default useJournalHandler;
