import React from 'react';
import styles from './Button.module.css';

export enum ButtonStyle {
  main = 'main',
  secondary = 'secondary',
  link = 'link',
  danger = 'danger',
}

interface ButtonProps {
  label: string;
  icon?: string;
  style: ButtonStyle;
  onClick: () => void;
}

const STYLES : {[key: string]: any } = {
  [ButtonStyle.main]: styles.main,
  [ButtonStyle.secondary]: styles.secondary,
  [ButtonStyle.link]: styles.link,
  [ButtonStyle.danger]: styles.danger,
}

const Button: React.FC<ButtonProps> = ({ label, icon, style, onClick }) => { // Add onClick here
  const buttonStyle = STYLES[style] || STYLES[ButtonStyle.secondary];

  return (
    <button className={`${styles.button} ${buttonStyle}`} onClick={onClick}>
      {icon && <img src={icon} alt="icon" className={styles.icon}/>}
      {label}
    </button>
  )
}

export default Button;
