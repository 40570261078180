import React, {MouseEvent, useEffect} from "react";
import styles from "./index.module.css";
import UpdateError from "./components/UpdateError/updateError";
import SettingsSection from "./components/SettingsSection";
import useCardDataProvider, {CardDataProviderResultStatus} from "../useCardsDataProvider";
import {RoutineActivityState, UserPreferencesCardResponse} from "./UserPreferencesCardResponse";
import WidgetCard from "../containers/WidgetCard";
import RoutinesSection from "./components/RoutinesSection";
import {useDispatch, useSelector} from "react-redux";
import {selectUserPreferences} from "../../../store/selectors";
import {SET_USER_PREFERENCES} from "../../../store/actionTypes";
import {UserPreferencesType} from "../../../store/reducers";
import PlanSection from "./components/PlanSection";

type Props = {
  card : UserPreferencesCardResponse
}

const UserPreferencesCard = ({ card } : Props) => {
  const cardDataResult = useCardDataProvider<UserPreferencesCardResponse>(card.type, {
    populatePath: card.populatePath
  })

  const userPreferences = useSelector(selectUserPreferences);
  const dispatch = useDispatch();

  useEffect(() => {
      if(cardDataResult.status !== CardDataProviderResultStatus.success || userPreferences.initialized) return;

      const data = cardDataResult.data as UserPreferencesCardResponse;
      const payload : UserPreferencesType = {
        morningRoutine: data.morningRoutine,
        eveningRoutine: data.eveningRoutine,
        eveningRoutineState: data.eveningRoutine?.state === RoutineActivityState.enabled,
        settings: data.settings,
        plan: data.plan,
        initialized: true
      }

      dispatch({
        type: SET_USER_PREFERENCES,
        payload: payload
      });
    }, [cardDataResult.status, userPreferences]);

  const handleRetry = (e: MouseEvent) => {
    e.preventDefault();
  }

  const render = (status : CardDataProviderResultStatus) => {
      switch (status) {
        case CardDataProviderResultStatus.success:
          return <CardBody />
        case CardDataProviderResultStatus.loading:
          return <LoadingIndicator/>
        case CardDataProviderResultStatus.failure:
          return <UpdateError handleRetry={handleRetry}/>
      }
  }

  return (
    <WidgetCard card={card}>
      <div className={styles.content}>
        {render(cardDataResult.status)}
      </div>
    </WidgetCard>
  )
}

const CardBody = ()  => {
  return (
    <>
      <RoutinesSection />
      <SettingsSection />
      <PlanSection />
    </>
  )
}

const LoadingIndicator = () => {
  return (
    <div className="loading_wrapper">
      <div id="loading"/>
    </div>
  )
}

export default UserPreferencesCard;
