import {useEffect, useState} from "react";
import {PaywallView} from "./PaywallViewPlugin";
import {usePurchaseEventsListeners} from "./usePurchaseEventsListeners";

export const usePaywallInitializer = () => {
  const [initialized, setInitialized] = useState(false);
  usePurchaseEventsListeners();

  useEffect(() => {
      if(initialized) return;

      const initialize = async () => {
        await PaywallView.configure({
            apiKey : process.env.REACT_APP_REVENUE_CAT_API_KEY,
        });

        setInitialized(true);
      }

      void initialize();
  }, [initialized]);

  return initialized;
};
