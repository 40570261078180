import {CardResponse} from "../../../model/CardsResponse";

export interface PlanResponse {
  name: string;
  details: string;
  endDate?: Date;
  offerUpgrade: boolean;
}

export enum RoutineActivityState {
  enabled = "enabled",
  disabled = "disabled"
}

export interface RoutineResponse {
  time: string;
  fieldName: string;
  state?: RoutineActivityState;
}

export interface ToggleSettingResponse {
  name: string;
  text: string;
  state: boolean;
}

export type UserPreferencesCardResponse = CardResponse & {
  settings: ToggleSettingResponse[];
  morningRoutine: RoutineResponse;
  eveningRoutine: RoutineResponse;
  plan: PlanResponse;
};
