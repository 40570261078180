import React from "react";
import styles from "./WelcomeCard.module.css";
import {WelcomeCardResponse} from "./WelcomeCardResponse";
import useTranslation from "../../../locale/hooks/use-translation";
import {DateFormat, useLocale} from "../../../locale";

type Props = {
  card : WelcomeCardResponse
}
export function WelcomeCard({card } : Props) {
    const {t} = useTranslation();
    const {formatDate} = useLocale();

    return (
        <div className={styles.container}>
            <div className={styles.welcomeMessage}>{t('card.welcome.hello')}, {card.userName} 👋</div>
            <div className={styles.entered}>{ card.welcomeTextKey ? t(card.welcomeTextKey, { params: card }) : card.welcomeText }</div>
            <div className={styles.joined}>{t('card.welcome.joined')}{formatDate(card.joinDate, { format: DateFormat.FULL_DATE })}</div>
        </div>
    )
}
