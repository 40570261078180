import React from 'react';
import './Homepage.css';
import { JournalResponse } from "../../../model/JournalResponse";
import SessionExpired from "../../../components/SessionExpired/SessionExpired";
import HeadMetaTags from "../../../components/HeadMetaTags";
import JournalList from "../../../components/journal/JournalList";
import TimezoneSetter from '../../../containers/TimezoneSetter';
import { AppHeader } from "../../../components/AppHeader/AppHeader";
import {HomepagePayload, User} from '../../../common/models';
import {callApi, CallMethod} from "../../../common/api";
import ErrorPage from "../../../components/ErrorPage/ErrorPage";
import {CardResponse} from "../../../model/CardsResponse";
import CardsList from "../../../components/cards/CardsList";
import AnalyticsTracker from "../../../components/AnalyticsTracker";
import Loading from "../../../components/loading";

interface HomepageState {
    isLoading: boolean,
    journal?: JournalResponse,
    user?: User,
    cards?: readonly CardResponse[],
    isSessionValid: boolean,
    showError? : boolean
    renewLink?: string,
}

class Homepage extends React.Component {
  state: HomepageState = {
    isLoading: true,
    isSessionValid: false
  }

  componentDidMount() {
    this.fetchHomepage();
  }

  fetchHomepage = () => {
    callApi<HomepagePayload>(CallMethod.get, `homepage/get`).then(response => {
      console.log(response);
      const state: HomepageState = {
        journal: response.journal,
        user: response.user,
        cards: response.cards,
        isLoading: false,
        isSessionValid: response.error === undefined,
        showError: false
      }

      this.setState(state)
    })
      .catch((error) => {
        const state: HomepageState = {
          isLoading: false,
          isSessionValid: false,
          renewLink: error.response?.renewLink,
          showError: false
        }
        if (error.response?.status !== 403) {
          state.showError = true

        }
        this.setState(state);
      })
  }

  renderHomepage = (user: User, journal: any, cards: any) => {
    return (
      <div>
        <AnalyticsTracker event="Mobile Web Page Visit" params={{ page : "homepage" }}/>
        <TimezoneSetter timezone={user?.timezone}/>
        <AppHeader value={!cards ? user : null}/>
        <div className="homepageCardsContainer">
        {cards && <CardsList cards={cards}/>}
        {journal &&
        <>
          <div className="divider"> </div>
          <div className="spacer"> </div>
          <JournalList value={journal} user={user} showAddEntryButton={true}/>
        </>
        }
        </div>
      </div>
    )
  }

  render() {
    const {isLoading, user, cards, journal, isSessionValid, renewLink, showError} = this.state;
    return (
      <div className="Homepage">
        <HeadMetaTags/>
        {isLoading
          ? (<Loading className="loadingContainer"/>)
          : isSessionValid
            ? this.renderHomepage(user!, journal, cards)
            : showError ? (<ErrorPage/>)
              : (<SessionExpired value={renewLink}/>)
        }
      </div>
    )
  }
}

export default Homepage;
