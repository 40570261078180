import React from 'react';
import useTranslation from "../../locale/hooks/use-translation";

interface ILoadingProps {
  className?: string;
}

export default function Loading({ className }: ILoadingProps) {
  const {t} = useTranslation();

  return (<div className={className}>{t("loading.text", { defaultText: 'Loading...' })}</div>)
}
