import {useEffect, useState} from "react";
import store from "../../store";
import {setUserState} from "../../store/actions";
import {UserStateInfo} from "../../store/reducers";
import {locateDeepLinkAppRoute} from "../../NativeApp/routing/app-deep-link-route-locator";

const useWebUserStateHandler = () => {
  const [webUserStateInitialized, setWebUserStateInitialized] = useState<boolean>(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const route = locateDeepLinkAppRoute(window.location.href);

    const userState: UserStateInfo = {
      token: urlParams.get('token') || route?.params.token || undefined,
      sourceId: urlParams.get("sid") || undefined,
    }

    setWebUserStateInitialized(true);
    store.dispatch(setUserState(userState))
  }, []);

  return webUserStateInitialized;
}

export default useWebUserStateHandler;
