import {callApi, CallMethod} from "../../common/api";
import {Dialog} from "@capacitor/dialog";

const API_PATH = 'user/auth/activate';

interface IActivationResponse {
  message: string;
}

export async function activateUser() : Promise<boolean> {
  try {
    await callApi<IActivationResponse>(CallMethod.post, API_PATH);
    return true;
  } catch (e: any) {
    console.error('Failed to activate user', e);
    if (e.response?.status) {
      const message = e.response.data?.message || 'Unknown error';
      await Dialog.alert({
        title: "Activation failed",
        message,
      });
    } else {
      await Dialog.alert({
        title: "Activation failed",
        message: 'Unknown error occurred',
      });
    }

    return false;
  }
}

export async function activateCopilot(activationToken: string) : Promise<boolean> {
  try {
    await callApi<IActivationResponse>(CallMethod.post, `${API_PATH}/copilot`, {
      data: {
        t: activationToken,
      }
    });

    return true
  } catch (e: any) {
    console.error('Failed to activate copilot user', e);
    if (e.response?.status) {
      const message = e.response.data?.message || 'Unknown error';
      await Dialog.alert({
        title: 'Activation failed',
        message,
      });
    } else {
      await Dialog.alert({
        title: 'Activation failed',
        message: 'Unknown error occurred',
      });
    }
    return false;
  }
}
