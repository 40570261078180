import React, {useContext} from "react";
import styles from "./Connect.module.css"
import AnalyticsTracker from "../../../../components/AnalyticsTracker";
import "../ValueProposition/ValueProposition.css";
import {IonPage} from "@ionic/react";
import whatsapp from "./assets/whatsapp.svg"
import messages from "./assets/messages.svg"
import TermsLinks from "../../components/TermsLinks";
import {trackEvent} from "../../../../common/analytics_tracker";
import {PlatformType} from "../../../../common/models";
import logo from "../../assets/logo.svg";
import Button, {ButtonStyle} from "../../../components/Button/Button";
import LoginPageResponseContext from "../../LoginPageResponseContext";

type PlatformIcon = {
  platform : PlatformType,
  icon : any
}

const icons : PlatformIcon[] = [{
    platform : PlatformType.WhatsApp,
    icon : whatsapp,
  }, {
    platform : PlatformType.AppleBusinessChat,
    icon : messages
  }
]

const ConnectPage : React.FC = () => {
  const loginResponse = useContext(LoginPageResponseContext);

  const renderButtons = () =>{
    return loginResponse.platforms.map(platformItem =>{
      const platformIcon = icons.find(icon => icon.platform === platformItem.platform);

      return (
        <Button label={platformItem.name} key={platformItem.name} style={ButtonStyle.secondary}  icon={platformIcon.icon} onClick={()=>{
          trackEvent("Get Started Button Clicked");
          window.open(platformItem.deepLink, "_blank");
        }} />
      )
    })
  }

  const renderPage = () =>{
    return (
      <IonPage>
        <AnalyticsTracker event={"Connect Page Loaded"}/>
        <div className={styles.container}>
            <div className={styles.bottomBg}> </div>
            <div className={styles.content}>
              <img src={logo} alt={"logo"} className={styles.logo}/>
              <div className={styles.bottom}>
                  <div className={styles.title}>Welcome Back!</div>
                  <div className={styles.text}>Please select the channel you already
                    <br/>
                    using when talking to Kai and we will
                    <br/>
                    connect your account.
                  </div>
                  <div  className={styles.buttonsContainer}>{renderButtons()}
                  <TermsLinks/>
                </div>
              </div>
            </div>
        </div>
      </IonPage>
    )
  }

  return (
    loginResponse ? renderPage() : null
  )
}

export default ConnectPage;
