import {authenticateOAuthUser} from "../../../common/user_authenticator";
import {Dialog} from "@capacitor/dialog";
import {useLoading} from "../../components/Loading/useLoading";
import {OAuthSignInDetails} from "./OAuthPlugin/models";
import {OAuthPlugin} from "./OAuthPlugin";
import {useEffect} from "react";


async function showSignInFailedAlert(){
  await Dialog.alert({
    title: "Sign in failed",
    message: "We were unable to sign you in, please try again later."
  });
}
export const useOAuthSignInEventsListeners = () => {
  const {showLoading, hideLoading} = useLoading();

  useEffect(() => {
    const initialize = async () => {
      const signInFailedListener = await OAuthPlugin.addListener('signInFailed', async (errorCode) => {
        console.log("******Sign in failed", errorCode);
        await showSignInFailedAlert();
      });

      const signInCompletedListener = await OAuthPlugin.addListener('signInCompleted', async (result : OAuthSignInDetails) => {
          removeListeners();
          showLoading({
            message : "Signing you in..."
          });

          const authResponse = await authenticateOAuthUser(result);

          hideLoading();

          if(!authResponse){
            await showSignInFailedAlert();
            return;
          }
      });

      const removeListeners = () => {
        signInFailedListener.remove();
        signInCompletedListener.remove();
      }
    }

    void initialize();
  }, []);
};



