import {getUser} from "../../../common/user-provider";
import {useEffect, useState} from "react";
import {NativeChatView} from "./NativeChatViewPlugin";

const useNativeChat = () => {
  const [initialized, setInitialized] = useState(false);
  const user = getUser();

  useEffect(() => {
    const init = async () => {
        console.log("Initializing NativeChatView");
        await NativeChatView.configure({apiKey : user.chat.appKey});
        await NativeChatView.connectUser({id : user.userId, token : user.chat.token});
    }

    if(!user.chat) return;
    init().then(()=>{
      console.log('NativeChatView configured');
      setInitialized(true);
    });
  }, [user]);

  return initialized;
};

export default useNativeChat;
