import React, {useEffect, useState} from "react";
import styles from "./JournalTrackerCard.module.css";
import numeral from 'numeral';
import star from "./assets/star.svg"
import useCardDataProvider, {CardDataProviderResultStatus} from "../useCardsDataProvider";
import {JournalTrackerCardResponse} from "./JournalTrackerCardResponse";
import WidgetCard from "../containers/WidgetCard";
import {DateFormat, useLocale} from "../../../locale";
import useTranslation from "../../../locale/hooks/use-translation";

type Props = {
  card : JournalTrackerCardResponse
}

export function JournalTrackerCard({card } : Props) {
  const initialData: JournalTrackerCardResponse = {
    longestStreak: 0,
    numEntriesThisMonth: 0,
    dailyEntriesStatusList: [],
    currentMonth: null,
  }

  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {formatDate} = useLocale();
  const {t} = useTranslation();

  const cardDataResult = useCardDataProvider<JournalTrackerCardResponse>(card.type, {
    populatePath: card.populatePath
  })

  useEffect(() => {
    if (!cardDataResult.data && cardDataResult.status === CardDataProviderResultStatus.loading) {
      setLoading(true)
      return;
    }

    if (!cardDataResult.data && cardDataResult.status === CardDataProviderResultStatus.failure) {
      setLoading(false)
      setError(true);
      return;
    }

    const response = cardDataResult.data;
    setLoading(false)
    setData(response)

  }, [cardDataResult])

  return (
    <WidgetCard card={card}>
      {((data?.dailyEntriesStatusList && data?.dailyEntriesStatusList.length > 0) && !loading) ?
        <div>
          <div className={styles.monthSelector}>
            {/* <img className={styles.chevronLeft} src={chevron} alt="" /> */}
            <div className={styles.month}>
              {formatDate(data.currentMonth, { format: DateFormat.MONTH })}
            </div>
            {/* <img className={styles.chevronRight} src={chevron} alt="" /> */}
          </div>
          <div className={styles.calendar}>
            {
              data.dailyEntriesStatusList.map((item: any, index: number) => {
                return <div key={index} className={styles.item}>
                  <div
                    className={`${item.status ? styles.day + ' ' + styles.active : styles.day} ${new Date().getDate() === item.day ? styles.current : ""}`}>{item.day}{item.status}</div>
                </div>
              })
            }
          </div>
          <div className={styles.stats}>
            <div className={styles.block}>
              <div className={styles.number}>
                {numeral(data.numEntriesThisMonth).format('0a')}
              </div>
              <div className={styles.label}>{t("card.journal-tracker.entries-this-month.label")}</div>
            </div>
            <div className={styles.separator}/>
            <div className={styles.block}>
              <img className={styles.star} src={star} alt=""/>
              <div className={styles.number}>
                {numeral(data.longestStreak).format('0a')}
              </div>
              <div className={styles.label}>{t("card.journal-tracker.consecutive-entries.label")}</div>
            </div>
          </div>
        </div>
        :
        (error ?
            <div>{t("card.journal-tracker.error")}</div>
            : loading ?
              <div className="loading_wrapper">
                <div id="loading"/>
              </div>
              :
              <h3>{t("card.journal-tracker.no-data")}</h3>
        )
      }
    </WidgetCard>
  )
}
