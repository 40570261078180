import {AppPage} from "../../routing/models";
import {AppPageRoutes} from "../../routing/AppRoutes";

export class ActivationRoutes implements AppPageRoutes {
  page = AppPage.activation;
  routes = [{
    path: "/activate",
  }]
  deepLinkPath: string = "/activate"
}
