import React from "react";
import styles from "./index.module.scss";
import JournalIcons from "../common/JournalIcons";
import Readonly from "./Readonly";
import Editable from "./Editable";

type Props = {
  topic: string;
  id: string;
  title?: string;
  content: string;
  isEditing: boolean;
  onDelete: (id: string) => void;
  onEdit: (id: string, content: string) => void;
  icon: number;
};

const JournalEntry = ({
  id,
  title,
  content,
  isEditing,
  onDelete,
  onEdit,
  icon,
}: Props) => {
  return (
    <div className={styles.entries}>
      <img src={JournalIcons[icon]} className={styles.headerIcon} alt="" />
      <div className={styles.entry}>
        <div className={styles.title}>{title}</div>
        {isEditing ? (
          <Editable
            id={id}
            content={content}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        ) : (
          <Readonly content={content} />
        )}
      </div>
    </div>
  );
};

export default JournalEntry;
