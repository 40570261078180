import React from "react";
import styles from "./ConnectApp.module.css";
import bg from "./assets/background.svg";
import connectImage from "./assets/connect-icon.svg";
import {trackEvent} from "../../../common/analytics_tracker";


const ConnectApp : React.FC = () =>{
  const deepLink = `${process.env.REACT_APP_DEEP_LINK_PREFIX}:/${window.location.pathname}`

  return (
    <div className={styles.container}
         style={{backgroundImage: `url(${bg})`, backgroundSize: "100%", backgroundRepeat: "no-repeat"}}>
      <img src={connectImage} alt={""} className={"image"}/>
      <div className={styles.textContainer}>
      <div className={styles.title}>Almost Done!</div>
      <div className={styles.info}>To complete the process and start using the app, click on the button below</div>
      </div>
      <div className={styles.buttonContainer}>
        <a href={deepLink} target={"_blank"} onClick={() =>{
          trackEvent("Complete Connect Clicked");
        }}>
          <div className={styles.connectButton}>
            <div className={styles.platformName}> Connect & Open App </div>
          </div>
        </a>
      </div>
      <div className={styles.issues}>
        <div>Experiencing issues connecting your app? <br /> Contact us at <a href={"mailto:support@kai.ai"}>support@kai.ai</a> </div></div>
    </div>
  )
}

export default ConnectApp
