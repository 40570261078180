import { JournalTopic } from "../../../common/models";
import JournalIcons from "../common/JournalIcons";

export enum FilterGroupType {
  all = 0,
  gratefulness = 1,
  focus,
  goodThings,
  affirmation,
  growth,
  weekend,
  openJournal
}

export enum FilterResultsGroupByType {
  none = "none",
  week = "week",
  month = "month",
}

export interface IJournalFilter {
  filterGroupType: FilterGroupType;
  icon: string;
  labelKey: string;
  groupBy: FilterResultsGroupByType;
}

export type FilteredEntry = {
  journals_topics: JournalTopic[];
} & ({ date: string } | { type: FilterResultsGroupByType; from: string; to: string; startTime: Date });

export const JournalFiltersList: IJournalFilter[] = [
  {
    filterGroupType: FilterGroupType.all,
    icon: JournalIcons.daily,
    labelKey: "journal.filters.all.label",
    groupBy: FilterResultsGroupByType.none,
  },
  {
    filterGroupType: FilterGroupType.gratefulness,
    icon: JournalIcons["1"],
    labelKey: "journal.filters.gratitude.label",
    groupBy: FilterResultsGroupByType.week,
  },
  {
    filterGroupType: FilterGroupType.focus,
    icon: JournalIcons["2"],
    labelKey: "journal.filters.focus.label",
    groupBy: FilterResultsGroupByType.week,
  },
  {
    filterGroupType: FilterGroupType.affirmation,
    icon: JournalIcons["4"],
    labelKey: "journal.filters.affirmation.label",
    groupBy: FilterResultsGroupByType.month,
  },
  {
    filterGroupType: FilterGroupType.goodThings,
    icon: JournalIcons["3"],
    labelKey: "journal.filters.good-things.label",
    groupBy: FilterResultsGroupByType.week,
  },
  {
    filterGroupType: FilterGroupType.growth,
    icon: JournalIcons["5"],
    labelKey: "journal.filters.growth.label",
    groupBy: FilterResultsGroupByType.week,
  },
  {
    filterGroupType: FilterGroupType.weekend,
    icon: JournalIcons["6"],
    labelKey: "journal.filters.weekend.label",
    groupBy: FilterResultsGroupByType.month,
  },
  {
    filterGroupType: FilterGroupType.openJournal,
    icon: JournalIcons["7"],
    labelKey: "journal.filters.open.label",
    groupBy: FilterResultsGroupByType.week,
  },
];
