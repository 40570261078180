import styles from "./index.module.css"
import React from "react";

type Props = {
  title : string
  children : any
  backgroundColor : string
}

const Card : React.FC<Props> =({title, children, backgroundColor})=> {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content} style={{backgroundColor:backgroundColor}}>
        {children}
      </div>
    </div>
  )
}

export default Card
