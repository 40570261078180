import { RootState } from "..";

export const selectUserPreferences = (state: RootState) => state.userPreferences;
export const selectUserPreferencesSettings = (state: RootState) => state.userPreferences?.settings;
export const selectAuthenticated = (state: RootState) => state.authenticated;
export const selectUser = (state: RootState) => state.user;
export const selectDeepLinkRoute = (state : RootState) => state.deepLinkRoute
export const selectAutoStartExercise = (state : RootState) => state.autoStartExercise

export const selectAppConfig = (state : RootState) => state.appConfig

export const selectShowPaywall = (state : RootState) => state.showPaywall

export const selectShowActivation = (state : RootState) => state.activation;

export const selectPendingActivationToken = (state : RootState) => state.pendingActivationToken;
