import {AppEventType, IAppEvent} from "./app-event";

export interface IPushNotificationTokenAppEvent{
  pushToken: string;
}

export interface IPushNotificationTokenRegisteredAppEvent extends IPushNotificationTokenAppEvent, IAppEvent {}

export function createPushTokenRegisteredEvent(token : string): IPushNotificationTokenRegisteredAppEvent {
  return {
    type: AppEventType.pushTokenRegistered,
    pushToken: token
  }
}

export interface IPushNotificationCreationErrorAppEvent extends IAppEvent {
  error: any;
}

export function createPushTokenCreationErrorEvent(error : any): IPushNotificationCreationErrorAppEvent {
  return {
    type: AppEventType.pushTokenCreationError,
    error: error
  }
}
