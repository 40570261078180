import {AppPage} from "../../routing/models";
import {AppPageRoutes} from "../../routing/AppRoutes";
import ChatTabPage from "./ChatTabPage";

export class ChatPageRoutes implements AppPageRoutes{
    page = AppPage.chat;
    component = ChatTabPage;
    routes = [{
        path : "/chat",
        exact : true
      }
    ]
  deepLinkPath = "/chat"
}
