import { useEffect } from "react";
import { UserTimezone } from "../../common/models";
import api from "../../common/api";

type Props = {
  timezone: UserTimezone;
};

const getTimezoneName = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    return undefined;
  }
};

const setTimezone = async (timezone: UserTimezone) => {
  const timezoneName = getTimezoneName();

  if (timezone?.name !== timezoneName) {
    await api.setTimezone({
      name: timezoneName,
    });
  }
};

const TimezoneSetter = ({ timezone }: Props) : any => {
  useEffect(() => {
    setTimezone(timezone);
  }, [timezone]);

  return null;
};

export default TimezoneSetter;
