import {Share, ShareOptions} from '@capacitor/share';
import React from "react";
import styles from "./index.module.css"
import {trackEvent} from "../../common/analytics_tracker";

import {ReactComponent as ShareIcon} from './assets/share-outline.svg';

type Props = {
  options : ShareOptions
  color : string
  trackData : any
};

const ExternalShare = ({options, color, trackData} : Props) => {
  return (
    <div className={styles.container}
         onClick={() => {
           Share.share(options)
             .then((result =>{
               const properties = {
                 ...trackData,
                 sharedTo : result
               }
               trackEvent("Content Shared", properties);
             }))
             .catch(() => {
               trackEvent("Share Content Canceled");
             })
         }}>
      <div className={styles.share}>
        <div style={{color : color}} className={styles.text}>SHARE</div>
        <div style={{color: color} } className={styles.img}><ShareIcon/></div>
      </div>
    </div>
  )
}

export default ExternalShare
