import React, { useEffect, useState } from "react";
// @ts-ignore
import WordCloud from 'react-d3-cloud';
import styles from "./TopicsWordCloudCard.module.css";
import topicsEmptyState from "./assets/topics-empty-state@3x.png"

import { getWindowDimensions } from "../../../hooks/useWindowDimensions";
import useCardDataProvider, {CardDataProviderResultStatus} from "../useCardsDataProvider";
import {TopicsWordCloudCardResponse} from "./TopicsWordCloudCardResponse";
import WidgetCard from "../containers/WidgetCard";
import useTranslation from "../../../locale/hooks/use-translation";

type Props = {
  card : TopicsWordCloudCardResponse
}

function getFontSize(value : number, maxValue : number, minValue : number){
    const minFontSize = 16;
    const maxFontSize = 80;

    const oldRange = maxValue - minValue;
    const newRange = maxFontSize - minFontSize;

    return (((value - minValue) * newRange) / oldRange) + minFontSize;
}

export function TopicsWordCloudCard({card} : Props) {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {t} = useTranslation();

  const cardDataResult = useCardDataProvider<TopicsWordCloudCardResponse>(card.type, {
    populatePath: card.populatePath
  })

  useEffect(() => {
    if (!cardDataResult.data && cardDataResult.status === CardDataProviderResultStatus.loading) {
      setLoading(true)
      return;
    }

    if (!cardDataResult.data && cardDataResult.status === CardDataProviderResultStatus.failure) {
      setLoading(false)
      setError(true);
      return;
    }

    const response = cardDataResult.data;
    setLoading(false)
    setTopics(response.topics)

  }, [cardDataResult])


  let data = null
  if (topics && topics.length > 0) {
    topics.sort((a, b) => {
      return b["magnitude"] - a["magnitude"];
    })
    const minValue = topics[topics.length - 1]["magnitude"] * 100;
    const maxValue = topics[0]["magnitude"] * 100;
    data = topics.map((item: any) => {
      if (item.magnitude < 0.1) return null;
      return {
        text: item["phrase"],
        value: getFontSize(item["magnitude"] * 100, maxValue, minValue)
      }
    }).filter((item: any) => item != null);
  }

  const maxWidth = 500;
  const {width} = getWindowDimensions();
  const cloudWidth = width < maxWidth ? width : maxWidth;
  const cloudHeight = 300;

  return (
    <WidgetCard card={card}>
      {data && topics && topics.length > 0 && !loading ?
        <div className={styles.cloud}>
          <WordCloud
            data={data}
            rotate={0}
            width={cloudWidth - 60}
            height={cloudHeight}
            font={"Recoleta"}
          />
        </div>
        :
        (loading ?
            <div className="loading_wrapper">
              <div id="loading"></div>
            </div>
            : error ?
              <div>Error</div>
              :
              <div>
                <img className={styles.TopicsWordCloudCard} src={topicsEmptyState} alt=""/>
                <div className="emptyStateLabel">{t("card.topics-word-cloud.no-data")}</div>
              </div>
        )
      }
    </WidgetCard>
  )
}

