import React from "react";
import styles from "./JournalingStats.module.css";
import numeral from 'numeral';
import {JournalingStatsCardResponse} from "./JournalingStatsCardResponse";
import Moment from "react-moment";

type Props = {
  card : JournalingStatsCardResponse,
}

export function JournalingStatsCard({card} : Props) {
  return (
    <div className={styles.container}>
      <div className={styles.entrered}>You’ve entered {numeral(card.journalEntriesCount).format("0a")} journals since
        you started.
      </div>
      <div className={styles.joined}>You joined on <Moment format="MMMM DD, YYYY">{card.joinDate}</Moment></div>

    </div>
  )
}
