import {useEffect, useState} from "react";
import {getUserPersistedInfo} from "../services/user_repository";
import {setUserState} from "../../store/actions";
import store from "../../store";
import {authenticateUser} from "../../common/user_authenticator";
import {useSelector} from "react-redux";
import {selectUser} from "../../store/selectors";
import {useHistory} from "react-router-dom";

const useNativeAuthentication = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [queriedUserInfo, setQueriedUserInfo] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const history = useHistory();

  useEffect(() => {
    getUserPersistedInfo().then((user) => {
      setQueriedUserInfo(true)
      if (user != null) {
        if(user.chat){
          store.dispatch(setUserState(user))
          setIsAuthenticated(true);
        }else{
          authenticateUser(user.token).then((response) => {
            if(response){
              setIsAuthenticated(true);
            }
          })
        }
      }
    })
  }, []);

  useEffect(() => {
    if(!user?.userId){
      setIsAuthenticated(false);
    }else{
      setIsAuthenticated(true);
    }
  }, [user, history]);

  return {isAuthenticated, queriedUserInfo};
};

export default useNativeAuthentication;
