import Route from "route-parser";

export enum AppPage {
  login = "login",
  journals= "journals",
  me = "me",
  practice = "practice",
  today = "today",
  logout = "logout",
  chat = "chat",
  paywall = "paywall",
  activation = 'activation',
}

export type PageRoutes = {
  page : AppPage,
  routes : Route[]
}
