import React, {useEffect} from "react";
import AnalyticsTracker from "../../../components/AnalyticsTracker";
import {IonContent} from "@ionic/react";

import {getUser} from "../../../common/user-provider";
import {useHistory} from "react-router-dom";
import {PaywallView} from "./PaywallViewPlugin";
import useAppConfig from "../../hooks/useAppConfig";

const PaywallPage = () => {
  const history = useHistory();
  const user = getUser();
  const config = useAppConfig();

  useEffect(() => {
    if(!user || !config?.paywall?.features) return;

    PaywallView.showPaywall({ features : config.paywall.features}  )
      .then(() => {
          history.goBack()
      });

  }, [user, config]);

  return (
    <IonContent>
      <AnalyticsTracker event={"App Paywall Loaded"}/>
    </IonContent>
  )
}

export default PaywallPage;

