import React, { useEffect, useState } from "react";
import styles from "./HappinessScoreCard.module.css";
import useCardDataProvider, {CardDataProviderResultStatus} from "../useCardsDataProvider";
import {HappinessScoreCardResponse} from "./HappinessScoreCardResponse";
import WidgetCard from "../containers/WidgetCard";
import HappinessScoreCardBadges from "./HappinessScoreCardBadges"

type Props = {
  card : HappinessScoreCardResponse
}

export function HappinessScoreCard({ card } : Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [cardData, setCardData] = useState(card);

  const cardDataResult = useCardDataProvider<HappinessScoreCardResponse>(card.type, {populatePath: card.populatePath});

  useEffect(() => {
    if (!cardDataResult.data && cardDataResult.status === CardDataProviderResultStatus.loading) {
      setLoading(true)
      return;
    }

    if (!cardDataResult.data && cardDataResult.status === CardDataProviderResultStatus.failure) {
      setLoading(false)
      setError(true);
      return;
    }

    setCardData(cardDataResult.data);
    setLoading(false)
  }, [cardDataResult])


  const renderErrorIndicator = () => {
    return (<div>Error</div>);
  }

  const renderLoadingIndicator = () => {
    return (
      <div className="loading_wrapper">
        <div id="loading"> </div>
      </div>
    )
  }

  const renderScoreInfo = () =>{
    if(!cardData.populated) return;
    const theme = cardData.theme;
    console.log("Card Data:", cardData);
    return (
        <div>
          <div className={styles.tagLine}> { cardData.tagLine } </div>
          <div className={styles.mainContainer}>
              <div className={styles.scoreContainer}>
                  <div  style={{ color : theme.scoreTextColor}} > {cardData.score} </div>
                  <div style={{ color : theme.separatorTextColor}} > / </div>
                  <div style={{ color : theme.maxScoreTextColor}} > {cardData.maxScore} </div>
              </div>
          </div>
          <div className={styles.footerContainer}>
            <div className={styles.footerTextContainer}> <div className={styles.footerText}>{cardData.footerText} </div> </div>
              <div className={styles.badgeContainer}> <img src={HappinessScoreCardBadges[theme.badge]} alt="" /> </div>
          </div>

        </div>
    )
  }

  return (
    <WidgetCard card={card}>
      {
        loading
          ? renderLoadingIndicator()
          : error
            ? renderErrorIndicator()
            : renderScoreInfo()
      }
    </WidgetCard>
  )
}
