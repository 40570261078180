import React, {useState} from "react";
import styles from "./index.module.scss";
import JournalEntry from "../JournalEntry";
import calendar from "../../cards/WelcomeCard/assets/calendar.svg";
import {sortTopics} from "./utils";
import EditButton from "./EditButton";
import {Edits} from "../../../common/models";
import {FilteredEntry} from "../JournalFilter/JournalFiltersList";
import {DateFormat, useLocale} from "../../../locale";

const DateTitle = ({ value }: { value: FilteredEntry }) => {
  const {formatDate} = useLocale();

  if ("date" in value) {
    const date = new Date(value.date);
    return <span>{formatDate(date, { format: DateFormat.DAY_OF_WEEK })}</span>
  }

  const from = new Date(value.from);
  const to = new Date(value.to);
  return (
    <div>
      <span>{formatDate(from, { format: DateFormat.RANGE })}</span> -{" "}
      <span>{formatDate(to, { format: DateFormat.RANGE })}</span>
    </div>
  );
};

type Props = {
  value: FilteredEntry;
  canEdit: boolean;
  onDelete: (id: string) => void;
  onEdit: (edits: Edits) => void;
};

const GroupedJournalEntries = ({ value, canEdit, onDelete, onEdit }: Props) => {
  const [isEditing, setEditing] = useState(false);
  const [edits, setEdits] = useState<Edits>({});

  const handleEdit = (id: string, content: string) => {
    setEdits({
      ...edits,
      [id]: content,
    });
  };

  const toggleEditing = () => {
    if (isEditing) {
      if (Object.keys(edits).length > 0) {
        onEdit(edits);
      }
      setEdits({});
    }
    setEditing(!isEditing);
  };

  return (
    <div className={styles.container}>
      <span className={styles.dateHeader}>
        <div className={styles.date} >
          <img className={styles.calendar} src={calendar} alt="" />
          <DateTitle value={value} />
        </div>

        {canEdit && (
          <EditButton isEditing={isEditing} onClick={toggleEditing} />
        )}
      </span>
      {sortTopics(value.journals_topics).map((journalTopic) =>
        journalTopic.journal_entries.map((entry) => (
          <JournalEntry
            topic={journalTopic.topic}
            id={entry.id}
            content={edits[entry.id] || entry.content}
            title={entry.title}
            key={entry.id}
            isEditing={canEdit && isEditing}
            onDelete={onDelete}
            onEdit={handleEdit}
            icon={entry.icon}
          />
        ))
      )}
    </div>
  );
};

export default GroupedJournalEntries;
