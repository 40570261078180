import {AppEventType, IAppEvent} from "./events/app-event";
import {
  IPushNotificationTokenAppEvent,
} from "./events/push-notifications-app-events";

export interface IAppReloadedAppEvent extends IPushNotificationTokenAppEvent, IAppEvent {}

export function createAppReloadedEvent(token : string): IAppReloadedAppEvent {
    return {
        type: AppEventType.applicationReloaded,
        pushToken: token
    }
}
