import React, { useEffect, useState } from "react";
import styles from "./UserJourneyCard.module.css";
import JourneyLevelBadges from "./JourneyLevelBadges";
import api from "../../../common/api";
import {setCardData} from "../../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {GlobalAppState} from "../../../store/reducers";
import { UserJourneyCardResponse } from "./UserJourneyCardResponse";
import WidgetCard from "../containers/WidgetCard";

import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

type Props = {
  card : UserJourneyCardResponse
}

export function UserJourneyCard({ card } : Props) {
  const [achievements, setAchievements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error] = useState(false);

  // @ts-ignore
  const cardData = useSelector((state: GlobalAppState) =>{
    return state.cardsData[card.type];
  }) as UserJourneyCardResponse;


  let chunkSize = 6
  const dispatch = useDispatch();

  useEffect(() => {
    const setJourneyInfo = (response : UserJourneyCardResponse) =>{
      let chunks = [] as any,
        i = 0,
        n = response.achievements.length;

      const allAchievements = response.achievements.map(x => x);
      while (i < n) {
        chunks.push(allAchievements.slice(i, i += chunkSize));
      }

      setAchievements(chunks)
      setLoading(false)
    }

    if(cardData){
      // @ts-ignore
      setJourneyInfo(cardData as UserJourneyCardResponse);
    }else{
      api.getCardData<UserJourneyCardResponse>(card.populatePath).then((response)=>{
        dispatch(setCardData(card.type, response))
        setJourneyInfo(cardData);
      }).catch(()=>{

      })
    }

  }, [cardData]);

  const renderLoadingIndicator = () => {
    return (
      <div className="loading_wrapper">
        <div id="loading"></div>
      </div>
    )
  }

  const renderError = () => {
    return <div>Error</div>;
  }

  const renderSlider = () => {
    return <Swiper
      modules={[Pagination]}
      spaceBetween={0}
      pagination={{
         clickable: true,
      }}
      slidesPerView={'auto'}
      watchOverflow={true}
      observer={true}
      observeParents={true}
      observeSlideChildren={true}
    >
      {
        achievements.map((achivmentsItem: any, index: number) => {
          return <SwiperSlide key={index}>
            <div className={styles.columns}>
              {
                achivmentsItem.map((item: any) => {
                  return <div
                    className={`${styles.column} ${item.status === "unlocked" ? styles.active : styles.inactive}`}
                    key={item.name}>
                    <div className={styles.wrapper}>
                      <img className={styles.badge} src={JourneyLevelBadges[item.level]} alt=""/>
                      <img className={styles.icon} src={item.iconUrl} alt=""/>
                      <svg width="100%" viewBox="0 0 250 250">
                        <circle cx="125" cy="125" r="100" stroke="#efe2f6" strokeWidth="15" fill="transparent"/>
                        <path d="M 125,25 A 100 100 0 1 1 125 225 A 100 100 0 1 1 125 25" stroke="#bd82dd"
                              strokeLinecap="round" strokeWidth="15" fill="transparent"
                              strokeDasharray={`${(item.progress / 100) * 628}, 628`}/>
                      </svg>
                    </div>
                    <div className={styles.title}>{item.name}</div>
                  </div>
                })
              }
            </div>
          </SwiperSlide>
        })
      }
    </Swiper>
  }

  const renderEmptyState = () => {
    return <div>
      {/* <img className={styles.UserJourneyCard} src={achievementsEmptyState} alt="" /> */}
      <div className="emptyStateLabel">Keep using Kai and I’ll show your life achievements when I have enough
        data
      </div>
    </div>
  }

  return (
    <WidgetCard card={card}>
      {achievements.length
        ? renderSlider()
        : loading
          ? renderLoadingIndicator()
          : error
            ? renderError()
            : renderEmptyState()
      }
    </WidgetCard>
  )
}
