import {AppPage} from "../../routing/models";
import {AppPageRoutes} from "../../routing/AppRoutes";
import ProfileTabPage from "./ProfileTabPage";

export class ProfilePagerRoutes implements AppPageRoutes {
  page = AppPage.me;
  component = ProfileTabPage;
  routes = [{
    path: "/me",
    exact : true
   }
  ]
  deepLinkPath = "/me"
}
