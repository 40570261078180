import {useRef, useEffect, useState, ReactPortal, ReactNode} from "react"
import { createPortal } from "react-dom"

interface IModalPortalProps {
  children: ReactNode;
}

export default function ModalPortal(props : IModalPortalProps) : ReactPortal {
  const ref = useRef<HTMLElement>()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.getElementById("portal")!
    setMounted(true)
  }, [])

  return mounted ? createPortal(props.children, ref.current!) : null;
}
