import {ILocale} from "./locale";

export const DEFAULT_LOCALE = 'en_US';
const SUPPORTED_LOCALES : { [key:string]: ILocale } = {
    'en_US': {
        code: 'en_US',
        shortCode: 'en',
        rtl: false,
    },
    'he_IL': {
        code: 'he_IL',
        shortCode: 'he',
        rtl: true,
    },
}

export function getLocaleByCode(code: string) : ILocale {
    return SUPPORTED_LOCALES[code];
}

export function getDefaultLocale() : ILocale {
    return SUPPORTED_LOCALES[DEFAULT_LOCALE];
}

export function getSupportedLocaleCodes() : string[] {
    return Object.keys(SUPPORTED_LOCALES);
}
