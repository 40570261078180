import React from "react";
import styles from "./SessionExpired.module.css";
import store from "../../store";
import AnalyticsTracker from "../AnalyticsTracker";
import useTranslation from "../../locale/hooks/use-translation";

export default function SessionExpired(props :any) {
  const {t} = useTranslation();

  const link = props.value || store.getState().user?.renewSessionLink;
  return (
    <div className={styles.container}>
      <AnalyticsTracker event="Mobile Web Page Visit" params={{ page : "expired" }}/>
      <div className={styles.content}>{t("session-expired.title")}</div>
      <div className={styles.content}><a href={link}>{t("session-expired.start-new-session")}</a></div>
    </div>
  )
}
