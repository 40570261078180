import {callApi, CallMethod} from "../../../../common/api";
import {useCallback} from "react";

interface IActivationApi {
  activationLink(t: string) : Promise<string>;
}

interface IActivationLinkResponse {
  link: string;
}

export default function useActivationApi() : IActivationApi {
  const activationLink = useCallback(async (t:string) : Promise<string> => {
    if (!t) {
      return null;
    }

    try {
      const {link} = await callApi<IActivationLinkResponse>(CallMethod.get, `activation/link`, {data: {t}});
      return link;
    } catch (e) {
      console.log('Error getting activation link', e);
      return null;
    }
  },[]);

  return {
    activationLink,
  }
}
