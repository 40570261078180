import {useEffect} from "react";
import {PaywallView} from "./PaywallViewPlugin";
import {handlePurchaseCompletedEvent} from "./purchase-completed-event-handler";
import {useDispatch, useSelector} from "react-redux";
import {selectUserPreferences} from "../../../store/selectors";
import {Dialog} from "@capacitor/dialog";
import {useLoading} from "../../components/Loading/useLoading";

export const usePurchaseEventsListeners = () => {
  const dispatch = useDispatch();
  const userPreferences = useSelector(selectUserPreferences);
  const {showLoading, hideLoading} = useLoading();

  useEffect(() => {
    const initialize = async () => {
      await PaywallView.addListener('purchaseFailed', async (errorCode) => {
        await Dialog.alert({
          title: "Oops!",
          message: `There was an issue processing your subscription. Please try again later or contact support if the problem persists. Error code: ${errorCode}`
        });
      });

      await PaywallView.addListener('purchaseCompleted', async () => {
        await handlePurchaseCompletedEvent(dispatch, showLoading, hideLoading, userPreferences);
      });
    }

    void initialize();
  }, []);
};



